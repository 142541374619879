import { useQuery } from '@apollo/client';
import { Typography, Disclaimer } from '@libs/components';
import { useApiResponse } from '@libs/hooks';
import { getCardsQuery, GetCardsType } from 'src/gql';
import { useCardDetector, useTranslation } from 'src/hooks';
import { CardOption } from '../card-option/CardOption';
import {
  NewCardWarningContainer,
  OptionsWrapper,
  CardsLabelWrapper,
} from './styles';

type AddCardWarningModalProps = {
  handleDeleteCardFromWarning: (val: GetCardsType) => void;
};
const AddCardWarningModal: React.FC<AddCardWarningModalProps> = ({
  handleDeleteCardFromWarning,
}) => {
  const { t } = useTranslation();
  const { cardDetector } = useCardDetector();

  const { data, loading } = useQuery(getCardsQuery);
  const { response } = useApiResponse({ data, loading });

  return (
    <NewCardWarningContainer>
      <Disclaimer
        shouldReverseDirection
        boldDescription={t('deposit_save_card_note_first')}
        description={t('deposit_save_card_note_second')}
      />
      <CardsLabelWrapper>
        <Typography type="subHeadline" fontFamily="primaryBold">
          {t('deposit_saved_cards')}
        </Typography>
      </CardsLabelWrapper>
      <OptionsWrapper>
        {response?.getCards.map((option, i) => {
          const { cardProvider, cardIcon } = cardDetector(
            option.cardMask as string
          );
          const handleIconClick = () => {
            handleDeleteCardFromWarning(option);
          };

          return (
            <CardOption
              handleDeleteClick={handleIconClick}
              key={i}
              cardProvider={cardProvider}
              cardIcon={cardIcon}
              cardMask={option.cardMask}
            />
          );
        })}
      </OptionsWrapper>
    </NewCardWarningContainer>
  );
};

export default AddCardWarningModal;
