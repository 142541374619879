import styled from 'styled-components';

import { Button, Typography } from '@libs/components';

type ContainerProps = {
  imgUrl: string;
};
export const Container = styled.div<ContainerProps>`
  border-radius: ${({ theme }) => theme.borderRadius.mediumHigh};
  max-height: 27rem;
  background-image: url(${({ imgUrl }) => imgUrl});
  background-repeat: no-repeat;
  padding: 3.2rem;
  margin: 2rem;
`;

export const Img = styled.img`
  height: 11rem;
  float: right;
  ${({ theme }) => theme.media.laptop`
      height: 13rem;
  `}
`;
export const CustomButton = styled(Button)`
  height: 5rem;
  ${({ theme }) => theme.media.laptop`
      margin-top: 3.2rem;
  `};
  ${({ theme }) => theme.media.laptopM`
      margin-top: 6rem;
  `};
`;
export const CustomTypography = styled(Typography)`
  display: block;
`;
export const Description = styled(Typography)`
  max-width: 15rem;
  ${({ theme }) => theme.media.laptop`
        max-width: unset;
    `}
`;
export const Wrapper = styled.div`
  margin: 1rem 0;
`;
