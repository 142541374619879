import { useCallback, useEffect } from 'react';

import { useModal, Button } from '@libs/components';

import { NotificationRefreshIcon } from 'src/assets';
import { WALLET_CHANGE_NOTIFICATION_MODAL_KEY } from 'src/constants';
import { useTranslation } from 'src/hooks';
import {
  CustomTypography,
  NotificationDescription,
  NotificationImage,
  NotificationModalWrapper,
} from './styles';

type NotificationModalProps = {
  hasDepositedCrypto?: boolean;
  userUUId?: number | string;
};
export const WalletsChangeNotificationModal: React.FC<
  NotificationModalProps
> = ({ hasDepositedCrypto, userUUId }) => {
  const { t } = useTranslation();
  const walletChangeNotificationModalKey =
    WALLET_CHANGE_NOTIFICATION_MODAL_KEY + userUUId;
  const handleNotificationModalClose = useCallback(() => {
    localStorage.setItem(walletChangeNotificationModalKey, 'true');
  }, [walletChangeNotificationModalKey]);
  const { Dialog, closeModal, openModal } = useModal(
    handleNotificationModalClose
  );

  const isNotMobileApp = !window.isFromMobileApp;

  useEffect(() => {
    const hasSeenWalletsChangeNotification = localStorage.getItem(
      walletChangeNotificationModalKey
    );

    if (
      hasDepositedCrypto &&
      !hasSeenWalletsChangeNotification &&
      userUUId !== undefined &&
      isNotMobileApp
    ) {
      openModal();
    }
  }, [
    hasDepositedCrypto,
    isNotMobileApp,
    openModal,
    userUUId,
    walletChangeNotificationModalKey,
  ]);

  const handleUnderstoodClick = () => {
    handleNotificationModalClose();
    closeModal();
  };
  return (
    <Dialog title=" ">
      <NotificationModalWrapper>
        <NotificationImage src={NotificationRefreshIcon} />
        <CustomTypography type="headline" fontFamily="primaryBold">
          {t('notification_wallet_update_title')}
        </CustomTypography>
        <NotificationDescription type="subHeadline" fontFamily="primaryMedium">
          {t('notification_wallet_update_description')}
        </NotificationDescription>

        <Button onClick={handleUnderstoodClick}>
          {t('common_understandable')}
        </Button>
      </NotificationModalWrapper>
    </Dialog>
  );
};
