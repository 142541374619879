import { useQuery } from '@apollo/client';
import { Fragment, ReactNode, useCallback, useMemo } from 'react';
import { useTheme } from 'styled-components';

import { FrStatuses, meQuery } from '@libs/graphql';
import { useApiResponse } from '@libs/hooks';
import { Typography } from '@libs/components';
import {
  BankCode,
  FeatureModuleEnum,
  JurisdictionsEnum,
} from '@libs/backend-common';

import { useTranslation } from 'src/hooks';
import { BOG_REFERENCE_CODE, CurrencyType } from 'src/constants';
import { getBitnetBankRequisitesQuery } from 'src/gql';
import {
  Disclaimer,
  BankIconDetector,
  FeatureRenderer,
  JurisdictionRenderer,
  OptionsHeader,
} from 'src/components';
import { bogIcon, tbcBankIcon } from 'src/assets';
import { getAlternativeAssetCurrency } from 'src/helpers';

import { DepositInfoRow } from '../DepositInfoRow/DepositInfoRow';
import { BankWireOptions } from '../deposit-fiat/DepositFiat';

import {
  BankIconContainer,
  BeneficiaryContainer,
  ContentWrapper,
  CustomSelectModal,
  DepositBankWireTitle,
  KYCDisclaimer,
  OptionsWrapper,
  RequisiteNameWrapper,
} from './style';

type DepositBankWireProps = {
  assetCode: string;
  selectedBank?: BankWireOptions;
  setSelectedBank: (val: BankWireOptions) => void;
};

const DepositBankWire: React.FC<DepositBankWireProps> = ({
  assetCode,
  selectedBank,
  setSelectedBank,
}) => {
  const { colors } = useTheme();

  const meRes = useQuery(meQuery);
  const { response: meResponse } = useApiResponse(meRes);
  const isUserVerified = meResponse?.me.frStatus === FrStatuses.VERIFIED;

  const { t } = useTranslation();

  const res = useQuery(getBitnetBankRequisitesQuery, {
    variables: {
      record: {
        assetCode: getAlternativeAssetCurrency(assetCode as CurrencyType),
      },
    },
  });
  const { response } = useApiResponse(res);

  const defaultReferenceDesc = t('bitnet_acc_deposit', {
    destinationCode: BOG_REFERENCE_CODE,
  });

  const filteredData = useMemo(() => {
    if (!response?.getBitnetBankRequisites) {
      return [];
    }

    if (!selectedBank) {
      return response?.getBitnetBankRequisites;
    }

    return response?.getBitnetBankRequisites.filter(
      (e) => e.bankCode === selectedBank
    );
  }, [response?.getBitnetBankRequisites, selectedBank]);

  const selectModalOptions = [
    {
      label: t('bank_bog'),
      icon: bogIcon,
      value: BankCode.BAGAGE22,
    },
    {
      label: t('bank_tbc'),
      icon: tbcBankIcon,
      value: BankCode.TBCBGE22,
    },
  ];

  const handleChange = (val: string | number) => {
    setSelectedBank(val as BankWireOptions);
  };

  const customRenderOptionHeader = useCallback((closeModal: () => void) => {
    return (
      <OptionsHeader closeModal={closeModal} title={t('common_choose_bank')} />
    );
  }, []);

  return (
    <FeatureRenderer
      acceptedFeature={[FeatureModuleEnum.BANK_WIRE_DEPOSIT]}
      shouldCheckKyc={false}
    >
      <JurisdictionRenderer acceptedJurisdictions={[JurisdictionsEnum.GE]}>
        <OptionsWrapper>
          <CustomSelectModal
            options={selectModalOptions}
            onChange={handleChange}
            selectedValue={selectedBank}
            placeholder={t('common_choose_bank')}
            renderOptionHeader={customRenderOptionHeader}
            optionFontFamily="primaryBold"
          />
        </OptionsWrapper>
      </JurisdictionRenderer>
      <ContentWrapper>
        {filteredData?.map((bank, index) => {
          const checkIfVisible = (key: string, e: ReactNode) => {
            return bank.visibleFields.includes(key) ? e : null;
          };

          return (
            <Fragment key={index}>
              {response?.getBitnetBankRequisites?.length === 1 ? (
                <DepositBankWireTitle>
                  <BankIconContainer>
                    <BankIconDetector code={bank.bankCode as BankCode} />
                  </BankIconContainer>
                  <Typography
                    type="bodyButton"
                    fontFamily="primaryBold"
                    color={colors.text.default}
                  >
                    {bank.bankName}
                  </Typography>
                </DepositBankWireTitle>
              ) : (
                <RequisiteNameWrapper>
                  <Typography type="subHeadline" fontFamily="primaryBold">
                    {bank.bankName}
                  </Typography>
                </RequisiteNameWrapper>
              )}

              <BeneficiaryContainer>
                {checkIfVisible(
                  'iban',
                  <DepositInfoRow title={t('bank_iban')} desc={bank?.iban} />
                )}
                {checkIfVisible(
                  'recipientName',
                  <DepositInfoRow
                    title={t('bank_beneficiary_name')}
                    desc={bank?.recipientName}
                  />
                )}
                {checkIfVisible(
                  'address',
                  <DepositInfoRow
                    title={t('bank_beneficiary_address')}
                    desc={bank?.address}
                  />
                )}
                {checkIfVisible(
                  'bankName',
                  <DepositInfoRow
                    title={t('bank_name')}
                    desc={bank?.bankName}
                  />
                )}
                {checkIfVisible(
                  'bankAddress',
                  <DepositInfoRow
                    title={t('bank_address')}
                    desc={bank?.bankAddress}
                  />
                )}
                {checkIfVisible(
                  'bankCode',
                  <DepositInfoRow
                    title={t('bank_code')}
                    desc={bank?.bankCode}
                  />
                )}
                {checkIfVisible(
                  'reference',
                  <DepositInfoRow
                    title={t('bank_payment_reference')}
                    desc={bank?.reference || defaultReferenceDesc}
                  />
                )}
              </BeneficiaryContainer>
            </Fragment>
          );
        })}
        <JurisdictionRenderer
          acceptedJurisdictions={[JurisdictionsEnum.EU, JurisdictionsEnum.GE]}
        >
          {!isUserVerified && (
            <KYCDisclaimer description={t('kyc_operation_disclaimer')} />
          )}
        </JurisdictionRenderer>
        <Disclaimer
          description={t('deposit_disclaimer')}
          boldDescription={t('deposit_disclaimer_bold')}
        />
      </ContentWrapper>
    </FeatureRenderer>
  );
};

export default DepositBankWire;
