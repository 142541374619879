import styled from 'styled-components';

import { Button, Typography } from '@libs/components';

export const ContentWrapper = styled.div`
  padding: 0 3.6rem 3.6rem 3.6rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  ${({ theme }) => theme.media.laptop`
    max-width: 50rem;
  `}
`;

export const InfoImage = styled.img`
  width: 8rem;
  height: 8rem;
  margin-bottom: 3.6rem;
`;

export const InfoText = styled(Typography)`
  text-align: center;
`;

export const HorizontalLine = styled.div`
  margin: 1.6rem 0;
  width: 100%;
  border: 0.1rem solid ${({ theme }) => theme.colors.borders.default};
`;

export const CloseButton = styled(Button)`
  margin-top: 3.6rem;
`;
