import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { Asset } from '@libs/types';
import {
  BankCode,
  CashTransactionType,
  FeatureModuleEnum,
  JurisdictionsEnum,
} from '@libs/backend-common';

import {
  useEnabledFeatures,
  useJurisdictionDetector,
  useModal,
} from 'src/hooks';
import { CurrencyType, FiatMethods } from 'src/constants';
import {
  DepositCard,
  DepositBankWire,
  CashTrade,
  FeatureRenderer,
  JurisdictionRenderer,
} from 'src/components';

import { useCheckCardTransactionInfo } from 'src/hooks';

import { DepositCardOptions } from '../deposit-card-options/DepositCardOptions';
import { PickDepositFiatMethod } from '../pick-deposit-fiat-method/PickDepositFiatMethod';
import { CardOperationTimer } from '../card-operation-timer/CardOperationTimer';

import { DepositFiatContainer } from './style';

type DepositFiatProps = {
  defaultAsset?: Asset;
  currentStep: number;
  onClick: () => void;
};

export type BankWireOptions = BankCode.BAGAGE22 | BankCode.TBCBGE22;

const getDefaultCurrencyValue = (
  currentJurisdiction: string,
  searchParams: URLSearchParams
) => {
  const selectedCurr = searchParams.get('selectedFiatAsset') as CurrencyType;
  if (currentJurisdiction === JurisdictionsEnum.EU) {
    return CurrencyType.EUR;
  } else if ([CurrencyType.BGEL, CurrencyType.BUSD].includes(selectedCurr)) {
    return selectedCurr;
  } else return CurrencyType.BGEL;
};

const DepositFiat: React.FC<DepositFiatProps> = ({ currentStep, onClick }) => {
  const { checkFeature } = useEnabledFeatures();
  const [searchParams] = useSearchParams();
  const { currentJurisdiction } = useJurisdictionDetector();
  const [selectedBankCode, setSelectedBankCode] = useState<BankWireOptions>();
  const isJurisdictionEu = currentJurisdiction === JurisdictionsEnum.EU;

  const [depositCardAmountValue, setDepositCardAmountValue] = useState('');

  const isCashDepositAvailable = checkFeature(FeatureModuleEnum.CASH_DEPOSIT);
  const isCardDepositAvailable = checkFeature(FeatureModuleEnum.CARD_DEPOSIT);
  const isBankWireDepositAvailable = checkFeature(
    FeatureModuleEnum.BANK_WIRE_DEPOSIT
  );

  const [currencyTogglerValue, setCurrencyTogglerValue] = useState<
    CurrencyType | undefined
  >(getDefaultCurrencyValue(currentJurisdiction, searchParams));
  const [fiatMethod, setFiatMethod] = useState<FiatMethods>();

  useEffect(() => {
    if (isBankWireDepositAvailable.isAvailable) {
      if (isJurisdictionEu) {
        setFiatMethod(FiatMethods.SEPA);
      }
    } else if (
      isCardDepositAvailable.isAvailable &&
      currencyTogglerValue === CurrencyType.USD
    ) {
      setFiatMethod(FiatMethods.CARD);
    } else if (
      isCashDepositAvailable.isAvailable &&
      !(currencyTogglerValue === CurrencyType.BGEL)
    ) {
      setFiatMethod(FiatMethods.CASH);
    }
  }, [
    currencyTogglerValue,
    isBankWireDepositAvailable.isAvailable,
    isCardDepositAvailable.isAvailable,
    isCashDepositAvailable.isAvailable,
    isJurisdictionEu,
  ]);

  const currencyForCard =
    currencyTogglerValue === CurrencyType.BUSD
      ? CurrencyType.USD
      : CurrencyType.EUR;

  const shouldCheckKyc = currentJurisdiction === JurisdictionsEnum.GE;

  const { closeModal, openModal, Dialog } = useModal();

  const { handleButtonClick, loading, date } = useCheckCardTransactionInfo(
    onClick,
    openModal,
    fiatMethod
  );

  const shouldWrapperHavePadding = !(
    currentStep === 1 &&
    [FiatMethods.CASH, FiatMethods.WIRE].includes(fiatMethod as FiatMethods)
  );

  const cardPaymentCurrency =
    currencyTogglerValue === CurrencyType.BGEL
      ? CurrencyType.GEL
      : currencyForCard;

  return (
    <JurisdictionRenderer
      acceptedJurisdictions={[JurisdictionsEnum.EU, JurisdictionsEnum.GE]}
    >
      <FeatureRenderer
        acceptedFeature={[
          FeatureModuleEnum.CASH_DEPOSIT,
          FeatureModuleEnum.CARD_DEPOSIT,
          FeatureModuleEnum.BANK_WIRE_DEPOSIT,
        ]}
        shouldNavigate={true}
        shouldCheckKyc={shouldCheckKyc}
      >
        <DepositFiatContainer
          data-testid="depositFiatContainer"
          hasPadding={shouldWrapperHavePadding}
        >
          {currentStep === 0 && (
            <>
              <PickDepositFiatMethod
                currencyTogglerValue={currencyTogglerValue}
                isBankWireDepositAvailable={isBankWireDepositAvailable}
                isCardDepositAvailable={isCardDepositAvailable}
                isCashDepositAvailable={isCashDepositAvailable}
                onClick={handleButtonClick}
                setCurrencyTogglerValue={setCurrencyTogglerValue}
                setFiatMethod={setFiatMethod}
                fiatMethod={fiatMethod}
                loading={loading}
                selectedBankCode={selectedBankCode}
                setSelectedBankCode={setSelectedBankCode}
              />
              <Dialog>
                <CardOperationTimer closeModal={closeModal} date={date} />
              </Dialog>
            </>
          )}
          {currentStep === 1 &&
            fiatMethod &&
            [FiatMethods.WIRE, FiatMethods.SEPA].includes(fiatMethod) &&
            currencyTogglerValue && (
              <DepositBankWire
                assetCode={currencyTogglerValue}
                selectedBank={selectedBankCode}
                setSelectedBank={setSelectedBankCode}
              />
            )}
          {currentStep === 1 &&
            fiatMethod &&
            [FiatMethods.DIGITALPAY, FiatMethods.CARD].includes(fiatMethod) && (
              <DepositCard
                setDepositCardAmountValue={setDepositCardAmountValue}
                changeCurrencyTogglerValue={setCurrencyTogglerValue}
                currency={cardPaymentCurrency}
                isDigitalPay={fiatMethod === FiatMethods.DIGITALPAY}
                handleNextStep={onClick}
              />
            )}
          {currentStep === 1 && fiatMethod === FiatMethods.CASH && (
            <CashTrade type={CashTransactionType.DEPOSIT} />
          )}

          {currentStep === 2 && fiatMethod === FiatMethods.CARD && (
            <DepositCardOptions
              depositCardAmountValue={depositCardAmountValue}
              currency={cardPaymentCurrency}
            />
          )}
        </DepositFiatContainer>
      </FeatureRenderer>
    </JurisdictionRenderer>
  );
};
export default DepositFiat;
