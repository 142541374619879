import { useEffect } from 'react';
import { useTheme } from 'styled-components';

import { Typography } from '@libs/components';

import { BlueInfoIcon } from 'src/assets';
import { useModal, useTranslation } from 'src/hooks';

import {
  CloseButton,
  ContentWrapper,
  HorizontalLine,
  InfoImage,
  InfoText,
} from './styles';

type WithdrawBankInfoModalProps = {
  shouldOpenModal?: boolean;
  isGelWithdrawNonWorkingHours?: boolean;
};

const WithdrawBankInfoModal: React.FC<WithdrawBankInfoModalProps> = ({
  shouldOpenModal = false,
  isGelWithdrawNonWorkingHours = false,
}) => {
  const { t } = useTranslation();
  const { colors } = useTheme();

  const {
    Dialog: BankInfoDialog,
    closeModal: closeBankInfoModal,
    openModal: openBankInfoModal,
  } = useModal();

  useEffect(() => {
    if (shouldOpenModal) {
      openBankInfoModal();
    }
  }, [openBankInfoModal, shouldOpenModal]);

  return (
    <BankInfoDialog
      title=""
      shouldHideHeaderBorder
      headerBackground={colors.surface.default}
    >
      <ContentWrapper>
        <InfoImage src={BlueInfoIcon} />
        {isGelWithdrawNonWorkingHours ? (
          <InfoText type="subHeadline" fontFamily="primaryMedium">
            {t('withdraw_fiat_weekend_info_gel')}
          </InfoText>
        ) : (
          <InfoText type="subHeadline" fontFamily="primaryMedium">
            {t('withdraw_fiat_weekend_info_start')}
            <Typography treatAs="b">
              {t('withdraw_fiat_weekend_info_bold')}
            </Typography>
            {t('withdraw_fiat_weekend_info_end')}
          </InfoText>
        )}
        <HorizontalLine />

        <CloseButton onClick={closeBankInfoModal} title={t('common_close')} />
      </ContentWrapper>
    </BankInfoDialog>
  );
};

export default WithdrawBankInfoModal;
