import styled, { css } from 'styled-components';

import { Tabs, Typography } from '@libs/components';
import { PageContainer } from 'src/components';

export const CustomPageContainer = styled(PageContainer)`
  ${({ theme }) => theme.media.laptop`
     padding: unset;
     padding-bottom: 11rem;
  `}
`;

export const AssetDetailsPageWrapper = styled.div`
  ${({ theme }) => theme.media.tabletL`

  margin: 0 0.5rem;
  `}
  ${({ theme }) => theme.media.laptopM`

  margin: 1.8rem 4rem;
`};
`;

export const AssetPricesWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  padding-top: 17.6rem;
  justify-content: center;
  gap: 1.6rem;
  flex-direction: column;
  ${({ theme }) => theme.media.tabletL`
    height: 40rem;
    flex-direction:row;
    margin-top: 1.6rem;
    padding:0;

  `}
`;

export const AssetPriceWrapper = styled.div`
  margin-top: 0.6rem;
  margin-bottom: 2rem;
  border: none;
`;

export const ChangesWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const AssetPriceBalanceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.surface.default};
  border-radius: ${({ theme }) => theme.borderRadius.highM};
  gap: 1.6rem;
  padding: 1.6rem;
  box-shadow: ${({ theme }) => theme.shadows.xs};

  ${({ theme }) => theme.media.tabletL`
      max-width: 38rem;
      height: 100%;
      justify-content: space-evenly;
  `}

  width: 100%;
`;

export const AssetChart = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.surface.default};
  border-radius: ${({ theme }) => theme.borderRadius.highM};
  box-shadow: ${({ theme }) => theme.shadows.xs};
  ${({ theme }) => theme.media.tabletL`
     min-width: 58rem;
     padding: 0 1.7rem 0 2.4rem;
  `}
`;

export const ChartWrapper = styled.div`
  height: 18.4rem;
  ${({ theme }) => theme.media.tabletL`
    height: 30.3rem;
  `}
`;

export const TabsAndTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 2rem 1.6rem 4.6rem;
  gap: 1.6rem;
  ${({ theme }) => theme.media.tabletL`
    flex-direction: row;
    margin:2rem 1.4rem 3.3rem 0;
    justify-content: space-between;
  `}
`;

export const CustomTabs = styled(Tabs)`
  height: 3.4rem;
  border-radius: 3.2rem;
  .tab-highlighter {
    height: 2.6rem;
    border-radius: 3.2rem;
  }
  ${({ theme }) => theme.media.tabletL`
    width: 23rem;
  `}
`;

export const CustomTypography = styled(Typography)`
  margin-left: 0.3rem;
  width: max-content;
`;

export const TransactionsWrapper = styled.div`
  margin-top: 1rem;
  background-color: ${({ theme }) => theme.colors.surface.default};
  ${({ theme }) => {
    const shadow = theme.shadows.sm as unknown as TemplateStringsArray;
    const radius = theme.borderRadius
      .mediumHigh as unknown as TemplateStringsArray;

    return theme.media.tabletL`
      margin-top:1.6rem;
      box-shadow: ${shadow};
      border-radius: ${radius};
    `;
  }};
`;

export const TableContainer = styled.div`
  padding-bottom: 8.3rem;
  ${({ theme }) => theme.media.tabletL`
  padding-bottom: 5.8rem;

  `}
`;

type TableWrapperType = {
  hasTransactions: boolean;
};

export const TableWrapper = styled.div<TableWrapperType>`
  ${({ theme, hasTransactions }) =>
    hasTransactions
      ? css`
          border-top: 1px solid ${theme.colors.borders.default};
          border-bottom: 1px solid ${theme.colors.borders.default};
        `
      : css`
          height: 100%;
        `}
`;
