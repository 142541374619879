import { Typography } from '@libs/components';
import styled from 'styled-components';

export const NotificationImage = styled.img`
  width: 10rem;
  height: 10rem;
  margin-bottom: 3.2rem;
`;

export const NotificationModalWrapper = styled.div`
  padding: 0 3.6rem 3.6rem 3.6rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const NotificationDescription = styled(Typography)`
  max-width: 42.8rem;
  text-align: center;

  margin-bottom: 3.2rem;
  margin-top: 0.8rem;
`;

export const CustomTypography = styled(Typography)`
  text-align: center;
`;
