import { CustomSvg, SvgIconDefaultProps } from '@libs/components';

const GooglePayIcon: React.FC<SvgIconDefaultProps> = () => {
  return (
    <CustomSvg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="32" height="32" rx="16" fill="#F5F6F7" />
      <path
        d="M23.9998 16.1884C23.9998 15.6036 23.9464 15.044 23.8472 14.5059H16.1711V17.5888L20.5919 17.5897C20.4126 18.6155 19.8356 19.4899 18.9514 20.0728V22.073H21.5829C23.1195 20.6801 23.9998 18.6211 23.9998 16.1884Z"
        fill="#4285F4"
      />
      <path
        d="M18.9499 20.0657C18.2174 20.5496 17.2741 20.8327 16.1706 20.8327C14.0389 20.8327 12.2305 19.4258 11.5829 17.5293H8.86841V19.592C10.2132 22.206 12.9773 23.9997 16.1706 23.9997C18.3777 23.9997 20.2318 23.2887 21.5814 22.0649L18.9499 20.0657Z"
        fill="#34A853"
      />
      <path
        d="M11.3278 16.0021C11.3278 15.4696 11.4184 14.9549 11.5834 14.471V12.4082H8.8689C8.31284 13.4891 8 14.7092 8 16.0021C8 17.2951 8.31379 18.5152 8.8689 19.5961L11.5834 17.5333C11.4184 17.0494 11.3278 16.5346 11.3278 16.0021Z"
        fill="#FABB05"
      />
      <path
        d="M16.1706 11.167C17.3752 11.167 18.454 11.5734 19.3057 12.3675L21.6377 10.0852C20.2213 8.79315 18.3748 8 16.1706 8C12.9783 8 10.2132 9.79369 8.86841 12.4076L11.5829 14.4704C12.2305 12.5739 14.0389 11.167 16.1706 11.167Z"
        fill="#E94235"
      />
    </CustomSvg>
  );
};
export default GooglePayIcon;
