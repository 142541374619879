import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';

import {
  InitSwapArgs,
  SwapCalculatorState,
  validateWithdrawalAddressQuery,
} from '@libs/graphql';
import { useApiResponse, useDebounce, useDimensions } from '@libs/hooks';
import {
  ADDRESS_NAME_MAX_LENGTH,
  SwapCalculator as NativeSwapCalculator,
  SwapCalculatorProps as NativeSwapCalculatorProps,
  SwapCalculatorTypesToOmit,
  useValidateWalletAddress,
} from '@libs/components';
import { screenSizes } from '@libs/theme';
import { SwapObjectType, SWAP_CALCULATOR_POOLING_INTERVAL } from '@libs/types';

import { swapClient } from 'src/setup';
import {
  calculateSwapMutation,
  GetSwapSettingsQuery,
  getWithdrawalAddressesQuery,
  initSwapMutation,
} from 'src/gql';
import {
  useRenderObjectByTheme,
  useThemeMode,
  useTranslation,
} from 'src/hooks';
import { hasAnyFieldEmpty } from 'src/helpers';
import { landingUrl } from 'src/constants';

type SwapCalculatorProps = Omit<
  NativeSwapCalculatorProps,
  SwapCalculatorTypesToOmit
> & {
  state?: SwapCalculatorState;
};

const SwapCalculator: React.FC<SwapCalculatorProps> = ({
  state,
  ...restProps
}) => {
  const { width } = useDimensions();
  const navigate = useNavigate();

  const { t, selectedLanguageCode } = useTranslation();
  const { selectedTheme } = useThemeMode();

  const [destinationAddress, setDestinationAddress] = useState('');

  const swapSettingsRes = useQuery(GetSwapSettingsQuery, {
    client: swapClient,
  });

  const { response, loading: responseLoading } =
    useApiResponse(swapSettingsRes);

  const [handleInitSwap, initSwapResponse] = useMutation(initSwapMutation, {
    client: swapClient,
  });
  const { response: initSwapRes, error: initSwapError } =
    useApiResponse(initSwapResponse);

  const sameAssets =
    state?.queryArgs?.destinationAssetCode ===
    state?.queryArgs?.sourceAssetCode;

  const sameNetwork =
    state?.queryArgs?.destinationNetworkId ===
    state?.queryArgs?.sourceNetworkId;

  const swapCalculationResponse = useQuery(calculateSwapMutation, {
    fetchPolicy: 'network-only',
    variables: state?.queryArgs ? { record: state.queryArgs } : undefined,
    client: swapClient,
    skip:
      hasAnyFieldEmpty(state?.queryArgs as Record<string, unknown>) ||
      state?.queryArgs?.amount === 0 ||
      (sameAssets && sameNetwork),
    notifyOnNetworkStatusChange: true,
  });
  const { response: calculationResponse, loading: swapCalculationLoading } =
    useApiResponse(swapCalculationResponse);

  useEffect(() => {
    if (swapCalculationResponse.error) {
      swapCalculationResponse.stopPolling();
      return;
    }
    if (!hasAnyFieldEmpty(state?.queryArgs as Record<string, unknown>)) {
      swapCalculationResponse.stopPolling();
      swapCalculationResponse.startPolling(SWAP_CALCULATOR_POOLING_INTERVAL);
    }

    return () => swapCalculationResponse.stopPolling();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  const triggetInitSwap = (args: InitSwapArgs) => {
    handleInitSwap({ variables: { record: args } });
  };

  const handleSwapCreation = (val?: string) => {
    navigate(`/swap-task-details?id=${val}`);
  };

  const handleTermsAndConditionsClick = () => {
    const additionalRouteQuery = `?selectedLangCode=${selectedLanguageCode}&selectedTheme=${selectedTheme}`;
    window.open(
      `${landingUrl}/legal/terms-and-conditions${additionalRouteQuery}`
    );
  };

  const backgroundColor = useRenderObjectByTheme(
    'none',
    width <= screenSizes.tabletL
      ? 'linear-gradient(180deg, rgba(255, 255, 255, 0.06) 1%, #222530 100%)'
      : 'linear-gradient( 180deg, rgba(255, 255, 255, 0.06) 1%, rgba(255, 255, 255, 0.12) 100%)'
  );

  const [qoutesAssetObj, setQouteAssetObj] = useState<
    SwapObjectType<JSX.Element>
  >({} as SwapObjectType<JSX.Element>);

  const assetCode = String(state?.queryArgs?.destinationAssetCode);
  const networkId = Number(state?.queryArgs?.destinationNetworkId);

  const { addressFormatError, validateWalletAddress } =
    useValidateWalletAddress(t('error_address_format_invalid') || '');

  const handleDestinationChange = useCallback(
    (val: unknown) => {
      if (qoutesAssetObj.networkCode) {
        validateWalletAddress(qoutesAssetObj.networkCode, val as string);
      }
    },
    [qoutesAssetObj.networkCode, validateWalletAddress]
  );
  useDebounce(destinationAddress, 200, handleDestinationChange);

  const address = useDebounce(destinationAddress, 200);

  const { data } = useQuery(getWithdrawalAddressesQuery, {
    fetchPolicy: 'network-only',
  });
  const { response: addressesResponse } = useApiResponse({ data });
  const withdrawalAddresses = addressesResponse?.getCryptoWithdrawalAddresses;

  const validateWithdrawalAddressRes = useQuery(
    validateWithdrawalAddressQuery,
    {
      skip: !address || !assetCode || !networkId || !!addressFormatError,
      variables: {
        record: {
          address,
          assetCode,
          networkId,
        },
      },
    }
  );

  const {
    errorMessage: addrValidationErr,
    clearErrorMessage: clearAddrValidationErr,
  } = useApiResponse(validateWithdrawalAddressRes);

  return (
    <NativeSwapCalculator
      initSwapError={initSwapError}
      swapCalculationData={calculationResponse?.calculateSwap}
      swapCalculationLoading={swapCalculationLoading}
      uuid={initSwapRes?.initSwap.uuid}
      responseLoading={responseLoading}
      swapOptions={response?.getSwapSettings}
      initSwapResponse={initSwapRes}
      initSwapResponseLoading={initSwapResponse.loading}
      swapCalculationResponse={swapCalculationResponse}
      backgroundColor={backgroundColor}
      onTermsAndConditionsClick={handleTermsAndConditionsClick}
      onSwapCreation={handleSwapCreation}
      triggetInitSwap={triggetInitSwap}
      swapDisclaimerText={t('swap_disclaimer')}
      baseAssetPlaceholder={t('swap_calculator_base_asset')}
      quoteAssetPlaceholder={t('swap_calculator_quote_asset')}
      fixedRateDesc={t('swap_calculator_fixed_rate_desc')}
      fixedRateTitle={t('swap_calculator_fixed_rate_title')}
      floatRateDesc={t('swap_calculator_floating_rate_desc')}
      floatRateTitle={t('swap_calculator_floating_rate_title')}
      destinationModalTitle={t('swap_calculator_destination_modal_title')}
      destinationAddressTitle={t('swap_calculator_destination_address_title')}
      swapDislaimerTermsAndConditions={t(
        'swap_disclaimer_terms_and_conditions'
      )}
      destinationAddressPlaceholder={t(
        'swap_calculator_destination_address_placeholder',
        { assetCode }
      )}
      swapTaskNamePlaceHolder={t('swap_task_name')}
      swapTaskNameTitle={t('swap_task_name_title')}
      swapButtonTitle={t('swap_calculator_button')}
      addressAvailabilityTime={t('swap_calculator_address_availability_time')}
      swapCalculatorTimerRapid={t('swap_calculator_timer_rapid')}
      swapCalculatorTimerNormal={t('swap_calculator_timer_normal')}
      swapCalculatorTimerProlonged={t('swap_calculator_timer_prolonged')}
      swapCalculatorTimerExtensive={t('swap_calculator_timer_extensive')}
      optionsModalTitle={t('withdraw_saved_addresses')}
      buttonTitle={t('withdraw_modal_confirm_title_address')}
      saveAddressModalTitle={t('withdraw_modal_confirm_title_address')}
      maxLengthErrText={t('validation_max_length', {
        max: ADDRESS_NAME_MAX_LENGTH,
      })}
      alreadyExistText={t('withdraw_address_already_saved')}
      inputPlaceHolder={t('withdraw_modal_input_placeholder')}
      chooseAddressText={t('withdraw_choose_address')}
      saveAddressText={t('withdraw_save_address')}
      saveAddressAfterText={t('withdraw_save_iban_address_message')}
      withdrawalAddresses={withdrawalAddresses}
      destinationAddress={destinationAddress}
      setDestinationAddress={setDestinationAddress}
      addrValidationErr={addressFormatError || t(addrValidationErr)}
      clearAddrValidationErr={clearAddrValidationErr}
      qoutesAssetObj={qoutesAssetObj}
      setQouteAssetObj={setQouteAssetObj}
      {...restProps}
    />
  );
};

export default SwapCalculator;
