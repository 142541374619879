import { FC } from 'react';

import { BankCode } from '@libs/backend-common';

import { bogIcon, tbcBankIcon } from 'src/assets';
import { useTranslation } from 'src/hooks';
import { FiatMethods } from 'src/constants';

import { BankWireOptions } from '../deposit-fiat/DepositFiat';
import { BankRadio, BankRadiosWrapper } from './styles';

type ChooseBankProps = {
  selectedBankCode?: BankWireOptions;
  setSelectedBankCode: (val: BankWireOptions) => void;
  fiatMethod?: FiatMethods;
};

export const ChooseBank: FC<ChooseBankProps> = ({
  selectedBankCode,
  setSelectedBankCode,
  fiatMethod,
}) => {
  const { t } = useTranslation();

  if (fiatMethod !== FiatMethods.WIRE) {
    return null;
  }

  const handleOptionClick = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    if (!e.currentTarget.id) return;
    setSelectedBankCode(e.currentTarget.id as BankWireOptions);
  };

  return (
    <BankRadiosWrapper>
      <BankRadio
        onClick={handleOptionClick}
        id={BankCode.TBCBGE22}
        title={t('bank_tbc')}
        checked={selectedBankCode === BankCode.TBCBGE22}
        icon={tbcBankIcon}
      />
      <BankRadio
        onClick={handleOptionClick}
        id={BankCode.BAGAGE22}
        checked={selectedBankCode === BankCode.BAGAGE22}
        title={t('bank_bog')}
        icon={bogIcon}
      />
    </BankRadiosWrapper>
  );
};
