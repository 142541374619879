import { gql, TypedDocumentNode } from '@apollo/client';
import { DefaultApiResponse, DefaultArg } from '@libs/graphql';

export type GetAssetSupportedNetworkRes = {
  isMemoRequired: boolean;
};

export type GetAssetSupportedNetworkResponse = {
  getAssetSupportedNetwork: DefaultApiResponse<GetAssetSupportedNetworkRes>;
};

type GetAssetSupportedNetworkArgs = {
  assetCode: string;
  networkId: number;
};

export const getAssetSupportedNetworkQuery: TypedDocumentNode<
  GetAssetSupportedNetworkResponse,
  DefaultArg<GetAssetSupportedNetworkArgs>
> = gql`
  query getAssetSupportedNetwork($record: GetAssetSupportedNetworkRecord!) {
    getAssetSupportedNetwork(record: $record) {
      isMemoRequired
    }
  }
`;
