import { TransparentButton } from '@libs/components';
import { DeleteIcon } from 'src/assets';
import styled from 'styled-components';

type OptionContainerProps = {
  backgroundColor: string;
};
export const OptionContainer = styled.div<OptionContainerProps>`
  width: 100%;
  display: flex;
  align-items: center;
  background-color: ${({ backgroundColor }) => backgroundColor};
  border-radius: ${({ theme }) => theme.borderRadius.high};
  border: 1px solid ${({ theme }) => theme.colors.borders.default};
`;

type OptionWrapperProps = {
  showLeftBorder?: boolean;
};
export const OptionWrapper = styled(TransparentButton)<OptionWrapperProps>`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.6rem;
  border-left: ${({ showLeftBorder, theme }) =>
    showLeftBorder ? `1px solid ${theme.colors.borders.default}` : ''};
`;
export const Icon = styled.img`
  width: 3.2rem;
  height: 3.2rem;
  background-color: ${({ theme }) => theme.colors.text.interactive};
  object-fit: contain;
  border-radius: ${({ theme }) => theme.borderRadius.high};
`;

export const OptionLabelsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-left: 1.4rem;
`;
export const OptionTitle = styled.div``;

export const OptionInfoWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const DropDownIconWrapper = styled.div`
  transform: rotate(-90deg);
  width: 2.6rem;
  height: 2.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const DropDownIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
`;

export const FlexStartDeleteIcon = styled(TransparentButton)`
  height: 100%;
  width: 5.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CustomDeleteIcon = styled(DeleteIcon)`
  width: 1.6rem;
  height: 1.6rem;
`;
