import { memo, useCallback, useMemo, useState } from 'react';

import { useTheme } from 'styled-components';

import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  CartesianGrid,
  ReferenceLine,
} from 'recharts';
import { CategoricalChartFunc } from 'recharts/types/chart/generateCategoricalChart';

import dayjs from 'dayjs';

import {
  getChartDomain,
  getChartInterval,
  getChartOffset,
} from '@libs/helpers';
import { formatDate, useDimensions } from '@libs/hooks';
import { screenSizes } from '@libs/theme';
import { CurrencySymbol } from '@libs/types';

import { ChartTooltip } from '../chart-tooltip/ChartTooltip';

export type ChartData = {
  createdAt?: Date | string;
  amount?: number | string;
};

type BalanceFinancialChartProps<T> = {
  data?: T[];
  currencySymbol?: CurrencySymbol;
  fontSize?: number;
  fontHeight?: number;
  dateFormat?: string | ((val: string) => string);
  surfaceColor?: string;
  shouldHideAxisLines?: boolean;
  shouldShowGraph?: boolean;
  shouldHideXAxis?: boolean;
  shouldHideYAxis?: boolean;
  shouldHideTooltip?: boolean;
  xAxisMargin?: number;
  tooltipPrecision?: number;
  customMargin?: {
    top: number;
    left: number;
    right: number;
    bottom: number;
  };
  tickInterval?: number;
  assetCode?: string;
};

const BalanceFinancialChart = <T extends ChartData>({
  data,
  currencySymbol,
  dateFormat,
  fontSize,
  fontHeight,
  customMargin,
  shouldHideXAxis,
  shouldShowGraph = true,
  tooltipPrecision,
  shouldHideYAxis,
  tickInterval,
  xAxisMargin,
  shouldHideTooltip = false,
  assetCode,
}: BalanceFinancialChartProps<T>) => {
  const { colors } = useTheme();

  const [areaDotColor, setAreaDotColor] = useState('transparent');

  const { width } = useDimensions();

  const defaultAmount = Number(data?.[0]?.amount || 0);

  const chartMin = useMemo(() => {
    if (!data || !data.length) {
      return 0;
    }

    const numbersArray = data.map((e) => Number(e.amount));

    return Math.min(...numbersArray);
  }, [data]);

  const chartMax = useMemo(() => {
    if (!data || !data.length) {
      return 0;
    }

    const numbersArray = data.map((e) => Number(e.amount));

    return Math.max(...numbersArray);
  }, [data]);

  const domain = useMemo(() => {
    return getChartDomain(chartMin, chartMax);
  }, [chartMax, chartMin]);

  const textProps = {
    fontSize: fontSize || 12,
    fontFamily: 'Satoshi-medium',
    height: fontHeight || 18,
  };

  const areaChartMargin = {
    top: 10,
    left: width < screenSizes.tablet ? 0 : 40,
    right: width < screenSizes.tablet ? 0 : 30,
    bottom: 0,
  };

  const id = assetCode || '';

  const margin = customMargin || areaChartMargin;

  const tickFormatter = (value: string | number) => {
    if (typeof value === 'string') value = +value;

    if (value > 1000) {
      value = (value / 1000).toFixed(2) + 'K';
    }

    return (currencySymbol || '') + value;
  };

  const xAxisTickFormatter = (val: string) => {
    if (!dateFormat) return val;

    if (typeof dateFormat === 'string') {
      return formatDate(dateFormat, dayjs(val));
    } else {
      return dateFormat(val);
    }
  };

  const off = useMemo(
    () => getChartOffset(defaultAmount, chartMin, chartMax),
    [chartMax, chartMin, defaultAmount]
  );

  const handleMouseMove = useCallback<CategoricalChartFunc>(
    (chart) => {
      if (shouldHideTooltip || !chart.activeTooltipIndex || !data) {
        return;
      }

      const hoverIndex = chart.activeTooltipIndex;

      const defaultAmount = Number(data?.[0].amount || 0);

      const hoverAmount = Number(data?.[hoverIndex].amount || 0);

      const colorToSet =
        hoverAmount >= defaultAmount
          ? colors.text.success
          : colors.text.critical;

      setAreaDotColor(colorToSet);
    },
    [colors, data, shouldHideTooltip]
  );

  const isChartLinear = chartMin === chartMax;

  const strokeColor = isChartLinear ? colors.text.success : `url(#stroke${id})`;

  const areaColor = isChartLinear
    ? colors.text.success
    : `url(#colorSplit${id})`;

  if (!data) return null;

  return (
    <ResponsiveContainer width="100%" height="100%">
      <AreaChart data={data} margin={margin} onMouseMove={handleMouseMove}>
        {shouldShowGraph && (
          <CartesianGrid
            strokeWidth={1}
            stroke={colors.borders.default + '40'}
          />
        )}

        <defs>
          <linearGradient id={`stroke${id}`} x1="0" y1="0" x2="0" y2="100%">
            <stop offset={off} stopColor={colors.text.success} />
            <stop offset={off} stopColor={colors.text.critical} />
          </linearGradient>
        </defs>

        <defs>
          <linearGradient
            id={`colorSplit${id}`}
            x1="0%"
            y1="0%"
            x2="0%"
            y2="100%"
          >
            <stop
              offset="0%"
              stopColor={colors.text.success}
              stopOpacity="0.3"
            />
            <stop
              offset={off}
              stopColor={colors.text.success}
              stopOpacity="0"
            />
            <stop
              offset={off}
              stopColor={colors.text.critical}
              stopOpacity="0"
            />
            <stop
              offset={'100%'}
              stopColor={colors.text.critical}
              stopOpacity="0.3"
            />
          </linearGradient>
        </defs>

        {shouldShowGraph && (
          <ReferenceLine
            y={defaultAmount}
            stroke={colors.borders.default}
            strokeDasharray="3 3"
          />
        )}

        <Area
          type="linear"
          dataKey="amount"
          stroke={strokeColor}
          fill={areaColor}
          activeDot={{ r: 7 + 3, fill: areaDotColor }}
          baseValue={defaultAmount}
        />
        <XAxis
          dataKey="createdAt"
          hide={shouldHideXAxis}
          tick={textProps}
          axisLine={false}
          tickLine={false}
          interval={getChartInterval(data.length, tickInterval)}
          tickFormatter={xAxisTickFormatter}
          includeHidden={false}
          domain={['auto', 'auto']}
          dx={xAxisMargin}
        />
        <YAxis
          orientation="right"
          hide={shouldHideYAxis}
          domain={domain}
          tickFormatter={tickFormatter}
          tick={textProps}
          tickLine={false}
          axisLine={false}
          allowDataOverflow={true}
        />
        {!shouldHideTooltip && (
          <Tooltip
            content={
              <ChartTooltip
                currencySymbol={currencySymbol}
                tooltipPrecision={tooltipPrecision}
              />
            }
          />
        )}
      </AreaChart>
    </ResponsiveContainer>
  );
};

export default memo(BalanceFinancialChart);
