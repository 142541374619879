import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLazyQuery, useQuery } from '@apollo/client';

import { Dayjs } from 'dayjs';

import { getDate, useApiResponse } from '@libs/hooks';
import { FrStatuses, meQuery } from '@libs/graphql';

import { FiatMethods } from 'src/constants';
import { getCardTransactionsInfoForUserQuery } from 'src/gql';

export const useCheckCardTransactionInfo = (
  nextStep: () => void,
  openModal: () => void,
  fiatMethod?: FiatMethods
) => {
  const navigate = useNavigate();

  const meRes = useQuery(meQuery);
  const { response: meResponse } = useApiResponse(meRes);

  const isUserVerified = meResponse?.me.frStatus === FrStatuses.VERIFIED;

  const [loading, setLoading] = useState(false);

  const [date, setDate] = useState<Dayjs>();

  const triggerLoad = () => setLoading(true);

  const cancelLoad = () => setLoading(false);

  const [getCardTransactionInfo] = useLazyQuery(
    getCardTransactionsInfoForUserQuery,
    {
      fetchPolicy: 'network-only',
    }
  );

  const handleButtonClick = async () => {
    if (fiatMethod !== FiatMethods.CARD) {
      nextStep();
      return;
    }

    if (!isUserVerified) {
      navigate('/kyc');
      return;
    }

    try {
      triggerLoad();

      const { data } = await getCardTransactionInfo();

      if (data) {
        const { cardTransactionAllowed, cardTransactionEnableDate } =
          data.getCardTransactionsInfoForUser;

        if (cardTransactionAllowed) {
          nextStep();
        } else {
          openModal();
        }

        if (cardTransactionEnableDate) {
          setDate(getDate(cardTransactionEnableDate));
        }
      }
    } finally {
      cancelLoad();
    }
  };

  return {
    loading,
    handleButtonClick,
    date,
  };
};
