import { Typography } from '@libs/components';
import { CurrencyType } from '@libs/types';

import { useTranslation } from 'src/hooks';

import {
  AddressContianer,
  AddressDetailsContainerWrapper,
  AddressDetailsWrapper,
  CustomInfoCircleIcon,
  DepositInfoCont,
} from '../style';

import { AddressDetail } from './AddressDetail';

type AddressDetailsProps = {
  address: string;
  bnbMemoAddress?: string;
  isBnbMemo?: boolean;
  initialAsset?: CurrencyType;
};

export const AddressDetails: React.FC<AddressDetailsProps> = ({
  address,
  isBnbMemo = false,
  bnbMemoAddress,
  initialAsset,
}) => {
  const { t } = useTranslation();

  const depositInfoContentTitle =
    initialAsset === CurrencyType.XRP
      ? 'deposit_tag_info_warning'
      : 'deposit_memo_info_warning';

  const addressDetailsTitle =
    initialAsset === CurrencyType.XRP ? 'common_tag' : 'common_memo';

  return (
    <AddressDetailsContainerWrapper>
      <AddressContianer>
        <AddressDetailsWrapper>
          <AddressDetail
            address={address}
            addressTitle={t('wallet_address')}
            scanQrTitle={t('scan_qr')}
            isBnbMemo={isBnbMemo}
            bottomBorderShouldhave={isBnbMemo}
          />
          {isBnbMemo && bnbMemoAddress && (
            <AddressDetail
              address={bnbMemoAddress}
              addressTitle={t(addressDetailsTitle)}
              isBnbMemo={isBnbMemo}
            />
          )}
        </AddressDetailsWrapper>

        {isBnbMemo && (
          <DepositInfoCont>
            <CustomInfoCircleIcon />
            <Typography type="caption" fontFamily="primaryMedium">
              {t(depositInfoContentTitle)}
            </Typography>
          </DepositInfoCont>
        )}
      </AddressContianer>
    </AddressDetailsContainerWrapper>
  );
};
