import { getAssetUrl } from '@libs/helpers';

export { default as bitnetLogo } from './bitnet-logo.svg';
export { default as VisaIcon } from './VisaIcon.png';
export { default as MastercardIcon } from './MastercardIcon.png';
export { default as bitnetLogoSmall } from './bitnet-small.svg';
export { default as GoogleTwoFaIcon } from './googleTwoFaIcon.svg';
export { default as SmsTwoFaIcon } from './smsTwoFaIcon.svg';
export { default as GlobeIcon } from './GlobeIcon.png';
export { default as DeleteUserIcon } from './deleteUser.png';
export { default as LoanFirstAd } from './LoanFirstAd.png';
export { default as LoanSecondAd } from './LoanSecondAd.png';
export { default as StakingAdsIcon } from './staking_ads.png';
export { default as ExcelLogo } from './ExcelLogo.png';
export { default as TetherIcon } from './TetherIcon.png';
export { default as CrexoneLogo } from './crexone-logo.svg';
export { default as CrexoneLogoBlack } from './crexone-logo-black.svg';
export { default as CrexoneMobileLogo } from './crexone-mobile-icon.svg';
export { default as DashboardBlurImg } from './dashboard-blur.svg';
export { default as EmailTwoFaIcon } from './EmailTwoFaIcon.png';
export { default as SwapCalculatorSkyBackground } from './SwapCalculatorSkyBackground.png';
export { default as SwapCalculatorSkyBackgroundLight } from './SwapCalculatorSkyBackgroundLight.png';
export { default as DeleteWarning } from './DeleteWarning.png';
export { default as EditWarning } from './EditWarning.png';
export { default as MobileFrameImage } from './frame.webp';
export { default as UsdtEtheriumImg } from './Eth.svg';
export { default as UsdtSolanaImg } from './UsdtSolana.svg';
export { default as UsdtTronImg } from './Thr.svg';
export { default as landingFeesTableMobile } from './landingFeesTableMobile.png';
export { default as LightEffect } from './LightEffect.png';
export { default as LightEffectMobile } from './lightEffectMobile.png';
export { default as AdvertismentDisclaimerPicture } from './AdvertismentDisclaimerPicture.png';
export { default as ActivateTwoFaPicture } from './ActivateTwoFaPicture.png';
export { default as GooglePlay } from './GooglePlay.png';
export { default as GooglePlayBlue } from './GooglePlayBlue.png';
export { default as AppStore } from './AppStore.png';
export { default as AppStoreBlue } from './AppStoreBlue.png';
export { default as BlueInfoIcon } from './BlueInfoIcon.png';
export { default as BLariIcon } from './BlariIcon.png';
export { default as BdollarIcon } from './BdollarIcon.png';
export { default as GooglePlayGray } from './googlePlayGray.png';
export { default as AppStoreGray } from './appStoreGray.png';
export { default as BrandColorModalFrame } from './brandColorModalFrame.png';
export { default as BrandColorModalFrameMobile } from './brandColorModalFrameMobile.png';
export { default as ReferralUsdtImg } from './ReferralUsdtImg.png';
export { default as NotificationRefreshIcon } from './notification-refresh-icon.png';
export { default as ApplePayIcon } from './ApplePayIcon.png';

export const CalculationDetailsBackground = getAssetUrl(
  'calculation-details-background.png'
);
export const MobileScreenForQrModal = getAssetUrl('qrModalMobileScreen.png');
export const landingFeesTable = getAssetUrl('landingFeesTable.png');
export const EmailVerificationImg = getAssetUrl('EmailVerificationImg.png');
export const ReferralsImg = getAssetUrl('Referrals.png');
export const DesertedIcon = getAssetUrl('DesertedIcon.png');
export const FullRepaymentImg = getAssetUrl('FullRepaymentImg.png');
export const ExclamationSignGlass = getAssetUrl('ExclamationSignGlass.png');
export const HourGlass = getAssetUrl('HourGlass.png');
export const RequireDepositBackgroundGlass = getAssetUrl(
  'RequireDepositBackgroundGlass.png'
);
export const BalanceKycBackground = getAssetUrl('BalanceKycBackground.png');
export const RequireDepositBackground = getAssetUrl(
  'RequireDepositBackground.png'
);
export const AccountCreatedImg = FullRepaymentImg;
export const ReferralsBitcoinImg = getAssetUrl('ReferralsBitcoinImg.png');
export const ReferralUsdtBanner = getAssetUrl('ReferralUsdtBanner.png');
export const PageNotFoundImage = getAssetUrl('pageNotFoundImage.png');
