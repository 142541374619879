import { FC } from 'react';

import { useQuery } from '@apollo/client';

import { useTheme } from 'styled-components';

import {
  Button,
  EurIcon,
  Typography,
  StateSetter,
  GelIcon,
  UsdIcon,
} from '@libs/components';
import { JurisdictionsEnum } from '@libs/backend-common';
import { useApiResponse } from '@libs/hooks';
import { meQuery } from '@libs/graphql';

import {
  BankIcon,
  tbcBankIcon,
  bogIcon,
  FiatCashOptions,
  VisaSvg,
  MasterCardSvg,
  ApplePayIcon,
  GooglePayIcon,
} from 'src/assets';

import { CurrencyType, FiatMethods } from 'src/constants';
import { InputRadius, CircledIcon, JurisdictionRenderer } from 'src/components';

import { useTranslation, useJurisdictionDetector } from 'src/hooks';

import { BankWireOptions } from '../deposit-fiat/DepositFiat';

import { OptionsIconWrapper } from './OptionsIconWrapper';
import {
  CustomInputRadiusContainer,
  CustomRadio,
  StyledDisclaimer,
  InputWrapper,
  CustomCurrencyTogglerWrapper,
  CustomInputRadius,
} from './styles';
import { ChooseBank } from './ChooseBank';

type Feature = {
  isAvailable: boolean;
};

type PickDepositFiatMethodProps = {
  fiatMethod?: FiatMethods;
  setFiatMethod: StateSetter<FiatMethods | undefined>;
  setCurrencyTogglerValue: StateSetter<CurrencyType | undefined>;
  currencyTogglerValue?: CurrencyType;
  isBankWireDepositAvailable: Feature;
  isCardDepositAvailable: Feature;
  onClick: () => void;
  isCashDepositAvailable: Feature;
  loading: boolean;
  selectedBankCode?: BankWireOptions;
  setSelectedBankCode: (bank?: BankWireOptions) => void;
};

export const PickDepositFiatMethod: FC<PickDepositFiatMethodProps> = ({
  setFiatMethod,
  fiatMethod,
  setCurrencyTogglerValue,
  currencyTogglerValue,
  isBankWireDepositAvailable,
  isCardDepositAvailable,
  isCashDepositAvailable,
  loading,
  onClick,
  setSelectedBankCode,
  selectedBankCode,
}) => {
  const meRes = useQuery(meQuery);

  const { response } = useApiResponse(meRes);
  const { colors } = useTheme();

  const { currentJurisdiction } = useJurisdictionDetector();

  const isJurisdictionEu = currentJurisdiction === JurisdictionsEnum.EU;

  const { t } = useTranslation();

  const currencyTitle = isJurisdictionEu
    ? 'selected_currency'
    : 'choose_currency';

  const isCardDepositEnabled = response?.me.isCardDepositEnabled;

  const handleSetCurrencyTogglerValue = (
    e: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    if (!e.currentTarget.id) return;
    setCurrencyTogglerValue(e.currentTarget.id as CurrencyType);
    setFiatMethod(undefined);
  };
  const handleSetFiatMethod = (
    e: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    if (!e.currentTarget.id) return;
    setFiatMethod(e.currentTarget.id as FiatMethods);

    if (selectedBankCode) {
      setSelectedBankCode(undefined);
    }
  };
  const nextStep = () => {
    onClick();
  };

  const depositWithCardDescription = !isCardDepositEnabled
    ? 'contact_to_support'
    : 'bank_card_deposit_fee';

  const bankCardDisabledStatus =
    currencyTogglerValue === CurrencyType.BUSD ||
    !isCardDepositAvailable.isAvailable ||
    !isCardDepositEnabled;

  const isBankSelected =
    fiatMethod === FiatMethods.WIRE ? selectedBankCode : true;

  return (
    <>
      <Typography
        type="subHeadline"
        fontFamily="primaryBold"
        color={colors.text.default}
      >
        {t(currencyTitle)}
      </Typography>
      <JurisdictionRenderer acceptedJurisdictions={[JurisdictionsEnum.GE]}>
        <CustomCurrencyTogglerWrapper>
          <InputRadius bottomRadiusFlat>
            <CustomRadio
              id={CurrencyType.BGEL}
              title={t('currency_gel')}
              icon={<GelIcon backgroundColor="#F2F6FF" />}
              checked={currencyTogglerValue === CurrencyType.BGEL}
              onClick={handleSetCurrencyTogglerValue}
              fontPrimaryBold="primaryBold"
            />
          </InputRadius>
          <InputRadius topRadiusFlat>
            <CustomRadio
              id={CurrencyType.BUSD}
              title={t('currency_usd')}
              icon={<UsdIcon backgroundColor="#F2F6FF" />}
              checked={currencyTogglerValue === CurrencyType.BUSD}
              onClick={handleSetCurrencyTogglerValue}
              fontPrimaryBold="primaryBold"
            />
          </InputRadius>
        </CustomCurrencyTogglerWrapper>
      </JurisdictionRenderer>
      <JurisdictionRenderer acceptedJurisdictions={[JurisdictionsEnum.EU]}>
        <InputWrapper>
          <InputRadius>
            <CustomRadio
              testId="radioWire"
              title={t('currency_eur')}
              checked={true}
              icon={<EurIcon />}
            />
          </InputRadius>
        </InputWrapper>
      </JurisdictionRenderer>
      <JurisdictionRenderer acceptedJurisdictions={[JurisdictionsEnum.GE]}>
        <StyledDisclaimer description={t('deposit_fiat_disclaimer')} />
      </JurisdictionRenderer>
      <Typography
        type="subHeadline"
        fontFamily="primaryBold"
        color={colors.text.default}
      >
        {t('choose_method')}
      </Typography>
      <CustomInputRadiusContainer>
        <JurisdictionRenderer acceptedJurisdictions={[JurisdictionsEnum.GE]}>
          <CustomInputRadius bottomRadiusFlat={true}>
            <CustomRadio
              testId="radioWire"
              title={t('bank_transfer_deposit')}
              description={t('bank_transfer_fee')}
              checked={fiatMethod === FiatMethods.WIRE}
              disabled={!isBankWireDepositAvailable.isAvailable}
              icon={
                <OptionsIconWrapper
                  firstIcon={tbcBankIcon}
                  secondIcon={bogIcon}
                />
              }
              id={FiatMethods.WIRE}
              onClick={handleSetFiatMethod}
            />

            <ChooseBank
              setSelectedBankCode={setSelectedBankCode}
              selectedBankCode={selectedBankCode}
              fiatMethod={fiatMethod}
            />
          </CustomInputRadius>
          <InputRadius topRadiusFlat={true} bottomRadiusFlat={true}>
            <CustomRadio
              testId="radioCard"
              id={FiatMethods.CARD}
              title={t('bank_card_transfer_deposit')}
              description={t(depositWithCardDescription)}
              icon={
                <OptionsIconWrapper
                  firstIcon={<VisaSvg />}
                  secondIcon={<MasterCardSvg />}
                />
              }
              // disabled
              // checked={false}
              disabled={bankCardDisabledStatus} // NOTE: enable card deposit
              checked={
                fiatMethod === FiatMethods.CARD && // NOTE: enable card deposit
                !(currencyTogglerValue === CurrencyType.USD)
              }
              onClick={handleSetFiatMethod}
            />
          </InputRadius>
          <InputRadius topRadiusFlat={true} bottomRadiusFlat={true}>
            <CustomRadio
              testId="radioCard"
              id={FiatMethods.DIGITALPAY}
              title={t('deposit_digital_methods')}
              description={t(depositWithCardDescription)}
              icon={
                <OptionsIconWrapper
                  firstIcon={ApplePayIcon}
                  secondIcon={<GooglePayIcon />}
                />
              }
              disabled={bankCardDisabledStatus}
              checked={
                fiatMethod === FiatMethods.DIGITALPAY &&
                !(currencyTogglerValue === CurrencyType.USD)
              }
              onClick={handleSetFiatMethod}
            />
          </InputRadius>
          <InputRadius topRadiusFlat={true}>
            <CustomRadio
              testId="radioCash"
              id={FiatMethods.CASH}
              title={t('bank_cash_transfer_deposit')}
              description={t('bank_cash_transfer_fee')}
              icon={<FiatCashOptions />}
              disabled={
                currencyTogglerValue === CurrencyType.BGEL ||
                !isCashDepositAvailable.isAvailable
              }
              checked={
                fiatMethod === FiatMethods.CASH &&
                !(currencyTogglerValue === CurrencyType.BGEL)
              }
              onClick={handleSetFiatMethod}
            />
          </InputRadius>
        </JurisdictionRenderer>
        <JurisdictionRenderer acceptedJurisdictions={[JurisdictionsEnum.EU]}>
          <InputRadius>
            <CustomRadio
              testId="radioWire"
              title={t('bank_transfer_sepa')}
              description={t('bank_transfer_fee')}
              checked={fiatMethod === FiatMethods.SEPA}
              disabled={!isBankWireDepositAvailable.isAvailable}
              icon={
                <CircledIcon color={colors.interactive.secondaryDisabled}>
                  <BankIcon />
                </CircledIcon>
              }
              id={FiatMethods.SEPA}
              onClick={handleSetFiatMethod}
            />
          </InputRadius>
        </JurisdictionRenderer>
      </CustomInputRadiusContainer>
      <Button
        className="start_deposit"
        testId="fiatBtn"
        disabled={!fiatMethod || !currencyTogglerValue || !isBankSelected}
        onClick={nextStep}
        title={t('common_continue')}
        isLoading={loading}
      />
    </>
  );
};

export default PickDepositFiatMethod;
