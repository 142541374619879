import { useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';
import { useLandingFeesTable } from '@libs/components/src/hooks';
import { filterData } from '@libs/helpers';
import { useApiResponse, useDebounce, useDimensions } from '@libs/hooks';
import { getNetworksQuery } from '@libs/graphql';
import { JurisdictionsEnum } from '@libs/backend-common';
import { getAllWithdrawalFeesQuery } from 'src/gql';
import { useJurisdictionDetector } from 'src/hooks';
import {
  BankIcon,
  CardIcon,
  UsdtEtheriumImg,
  UsdtTronImg,
  UsdtSolanaImg,
} from 'src/assets';
import { useTranslation } from './useTranslation';

export const useLandingFeesTableWrapper = () => {
  const { t } = useTranslation();
  const { width } = useDimensions();
  const [cryptoWithdrawSearchValue, setCryptoWithdrawSearchValue] =
    useState('');
  const [fullyLoaded, setFullyLoaded] = useState(true);

  const withdrawalRes = useQuery(getAllWithdrawalFeesQuery);
  const { response: withdrawalFeesRes } = useApiResponse(withdrawalRes);

  const res = useQuery(getNetworksQuery);
  const { response: networkResp } = useApiResponse(res);

  const term = useDebounce(cryptoWithdrawSearchValue, 500) as string;

  const filteredSupportedAssets = useMemo(() => {
    return filterData(withdrawalFeesRes?.getAllWithdrawalFees, term, [
      'assetCode',
      'assetName',
    ]);
  }, [term, withdrawalFeesRes?.getAllWithdrawalFees]);

  const { currentJurisdiction } = useJurisdictionDetector();

  const isEuJurisdiction = currentJurisdiction === JurisdictionsEnum.EU;
  const isJurisdictionGe = currentJurisdiction === JurisdictionsEnum.GE;

  const landingFeesTableProps = {
    anyGeorgianBank: t('common_any_georgian_bank'),
    currencyGelOrEur: isJurisdictionGe ? t('currency_gel') : t('currency_eur'),
    currencyUsd: t('currency_usd'),
    processingTime: isJurisdictionGe
      ? t('common_instant')
      : t('common_processing_time'),
    commonInstant: t('common_instant'),
    commonAny: t('common_any'),
    commonCard: t('common_card'),
    currencyGelUsd: isJurisdictionGe
      ? t('currency_gel_usd')
      : t('currency_eur'),
    commonBankwireBogOrSepa: isJurisdictionGe
      ? t('common_bank_wire_bog')
      : t('common_bank_wire_sepa'),
    commonBankwireOther: t('common_bank_wire_other_bank'),
    commonCurrency: t('common_currency'),
    commonFee: t('common_fee'),
    tableProccesing: t('table_processing'),
    commonLimit: t('common_limit'),
    walletActionDep: t('wallet_action_deposit'),
    withdrawMeth: t('landing_fees_withdrawal_methods'),
    commonBankwireTbc: t('common_bank_wire_tbc'),
    commonShortAny: t('common_short_any'),
    commonMeth: t('common_method'),
    commonFeeShort: t('common_fee_short'),
    tableAssets: t('table_assets'),
    tableAmount: t('table_amount'),
    tableAmountShort: t('table_amount_short'),
    tableBitnetFee: t('table_bitnet_fee'),
    tablePrice: t('table_price'),
    tableBlockchainFee: t('table_blockchain_fee'),
    tableDestinationBank: t('table_destination_bank'),
    landingFeesFundingOptions: t('landing_fees_funding_options'),
    landingFeesFundingDisclaimer: isJurisdictionGe
      ? t('landing_fees_funding_disclaimer')
      : '',
    landingFeesCryptoWithdrawalFees: t('landing_fees_crypto_withdrawal_fees'),
    landingFeesInternalCryptoTransferFee: t(
      'landing_fees_internal_crypto_transfer_fee'
    ),
    landingFeesWithdrawalMethods: t('landing_fees_withdrawal_methods'),
    landingFeesWithdrawalMethodsDisclaimer: isJurisdictionGe
      ? t('landing_fees_withdrawal_methods_disclaimer')
      : '',
    landingFeesWireTransferWithdrawalFees: t(
      'landing_fees_wire_transfer_withdrawal_fees'
    ),
    landingFeesCardWithdrawalFees: t('landing_fees_card_withdrawal_fees'),
    landingFeesAmount: 'landing_fees_amount',
    landingFeesFixedAmount: 'landing_fees_fixed_amount',
    landingFeesCryptoWithdrawalMethodsDisclaimer: t(
      'landing_fees_crypto_withdrawal_methods_disclaimer'
    ),
    withdrawalFeesRes,
    width,
    shouldRender: isEuJurisdiction,
    isJurisdictionGe,
    BankIcon,
    CardIcon,
    UsdtEtheriumImg,
    UsdtTronImg,
    UsdtSolanaImg,
    commonUnlimited: t('common_unlimited'),
    commonUnlimitedShort: t('common_unlimited_short'),
    walletActionDeposit: t('wallet_action_deposit'),
    commonWithdrawMethods: t('common_withdraw_methods'),
    withdrawCryptoFee: t('withdraw_crypto_fee'),
    convertFee: t('convert_fee'),
    cryptoWithdrawSearchValue,
    setCryptoWithdrawSearchValue,
    filteredSupportedAssets: filteredSupportedAssets,
    fullyLoaded,
    setFullyLoaded,
    bankWireTbcDeposit: t('common_bank_wire_tbc_deposit'),
    bankWireBogDeposit: t('common_bank_wire_bog_deposit'),
    networkResp,
  };

  return useLandingFeesTable(landingFeesTableProps);
};
