import { HistoryPeriodEnumKeys } from 'src/gql';
import { ExcludedDayEnum } from 'src/types';

export const TITLES: Record<ExcludedDayEnum, string> = {
  [HistoryPeriodEnumKeys.ONE_DAY]: 'time_24_hours',
  [HistoryPeriodEnumKeys.ONE_WEEK]: 'time_one_week',
  [HistoryPeriodEnumKeys.ONE_MONTH]: 'time_one_month',
  [HistoryPeriodEnumKeys.THREE_MONTHS]: 'time_three_months',
  [HistoryPeriodEnumKeys.SIX_MONTHS]: 'time_six_months',
};
