import { useCallback, useEffect, useState } from 'react';
import { useTheme } from 'styled-components';
import { useMutation } from '@apollo/client';
import Lottie from 'lottie-react';

import {
  ADDRESS_NAME_MAX_LENGTH,
  Button,
  EmptyState,
  resultFeedbackSuccessAnimation,
  TransparentButton,
  Typography,
  useModal,
} from '@libs/components';
import { ConfirmationModalType } from '@libs/types';

import {
  Address,
  deleteCryptoAddressMutation,
  getCryptoAddressesByAssetQuery,
  updateCryptoAddressNameMutation,
} from 'src/gql';
import { DialogProps, useTranslation } from 'src/hooks';
import { EmptyStateWrapper } from 'src/components/transaction-table/styles';
import { DeleteIcon, DesertedIcon } from 'src/assets';
import { useConfirmationModal } from 'src/hooks/useConfirmationModal';

import { AddressDetails } from './AddressDetails';
import { AddressCopy } from './AddressCopy';
import { AddressNameEdit } from './AddressNameEdit';

import {
  ActionsWrapper,
  AddressHeaderWrapper,
  AddressWrapper,
  AllAddress,
  CloseButton,
  CustomInput,
  CustomTableCell,
  DeleteIconWrapper,
  DetailsButton,
  DetailsWrapper,
  GenerateButton,
  GradientBackground,
  IconWrapper,
  MultiAddressViewContainer,
  NewAddressGenerationWrapper,
} from './styles';

type MultiAddressViewProps = {
  addresses: Address[];
  handleGenerateNetwork: () => void;
  isCreatingAddress: boolean;
  assetCode: string;
  networkId: number;
  loading?: boolean;
  NewAddressDialog: (props: DialogProps) => JSX.Element;
  closeNewAddressModal: () => void;
  generatedAddressId?: number;
};

export const MultiAddressView: React.FC<MultiAddressViewProps> = ({
  addresses,
  handleGenerateNetwork,
  isCreatingAddress,
  assetCode,
  networkId,
  loading,
  NewAddressDialog,
  closeNewAddressModal,
  generatedAddressId,
}) => {
  const { t } = useTranslation();
  const { colors } = useTheme();
  const [selectedAddress, setSelectedAddress] = useState<Address>();
  const { Dialog, openModal, closeModal } = useModal();
  const [addressName, setAddressName] = useState('');

  const buttonTitle = t('common_details');
  useEffect(() => {
    if (selectedAddress) {
      setAddressName(selectedAddress.name);
    }
  }, [selectedAddress]);
  const [deleteRule] = useMutation(deleteCryptoAddressMutation);

  useEffect(() => {
    const newAddress = addresses.find(
      (address) =>
        address.id === selectedAddress?.id &&
        address.name !== selectedAddress.name
    );

    if (newAddress) {
      setSelectedAddress(newAddress);
    }
  }, [addresses, selectedAddress]);

  const [deleteAddressId, setDeleteAddressId] = useState<number>();

  const handleDeletion = useCallback(() => {
    if (deleteAddressId) {
      deleteRule({
        variables: { record: { id: deleteAddressId } },
        refetchQueries: [
          {
            query: getCryptoAddressesByAssetQuery,
            variables: {
              record: {
                networkId: networkId,
                assetCode: assetCode,
              },
            },
          },
        ],
      });
    }
  }, [assetCode, deleteAddressId, deleteRule, networkId]);

  const { Dialog: ConfirmationDialog, openModal: openConfirmationModal } =
    useConfirmationModal(undefined, handleDeletion);
  const [createAddressName, setCreateAddressName] = useState<string>(
    t('withdraw_address_placeholder')
  );

  const [changeAddressName] = useMutation(updateCryptoAddressNameMutation, {
    refetchQueries: [
      {
        query: getCryptoAddressesByAssetQuery,
        variables: {
          record: {
            networkId: networkId,
            assetCode: assetCode,
          },
        },
      },
    ],
  });
  const handleAddressGenerateClick = () => {
    setCreateAddressName(t('withdraw_address_placeholder'));
    if (createAddressName && generatedAddressId) {
      changeAddressName({
        variables: {
          record: { id: generatedAddressId, name: createAddressName },
        },
      });
    }
    closeNewAddressModal();
  };

  const inputErrorMessage =
    createAddressName.length > ADDRESS_NAME_MAX_LENGTH
      ? t('validation_max_length', { max: ADDRESS_NAME_MAX_LENGTH })
      : '';

  return (
    <MultiAddressViewContainer>
      <AllAddress>
        {addresses.map((address, i) => {
          const handleClick = () => {
            setSelectedAddress(address);
            openModal();
          };
          const hanldeDeleteButtonClick = (e: React.MouseEvent) => {
            e.stopPropagation();
            openConfirmationModal();
            setDeleteAddressId(+address.id);
          };
          return (
            <AddressWrapper key={i}>
              <AddressHeaderWrapper>
                <CustomTableCell
                  description={address.address}
                  titleType="primaryBold"
                  titleSize="subHeadline"
                  color={colors.text.secondary}
                  title={address.name}
                  descType="bodyButton"
                />
                <TransparentButton onClick={hanldeDeleteButtonClick}>
                  <DeleteIconWrapper>
                    <DeleteIcon color={colors.text.default} />
                  </DeleteIconWrapper>
                </TransparentButton>
              </AddressHeaderWrapper>

              <ActionsWrapper>
                <AddressCopy text={address.address} />
                <DetailsButton
                  buttonType="combined"
                  title={buttonTitle}
                  size="md"
                  onClick={handleClick}
                />
              </ActionsWrapper>
            </AddressWrapper>
          );
        })}
        {!loading && !addresses.length && (
          <EmptyStateWrapper>
            <EmptyState
              img={DesertedIcon}
              title={t('error_address_not_found')}
            />
          </EmptyStateWrapper>
        )}
      </AllAddress>

      <GradientBackground />
      <GenerateButton
        onClick={handleGenerateNetwork}
        title={t('generate_another_address')}
        isLoading={isCreatingAddress}
      />
      <Dialog leftSpace={26} title={addressName} headerButtonText=" ">
        {!!selectedAddress && (
          <AddressNameEdit
            setAddressName={setAddressName}
            addressName={addressName}
            selectedAddress={selectedAddress}
            assetCode={assetCode}
            networkId={networkId}
          />
        )}
        {!!selectedAddress && (
          <DetailsWrapper>
            <AddressDetails address={selectedAddress?.address} />

            <CloseButton title={t('common_close')} onClick={closeModal} />
          </DetailsWrapper>
        )}
      </Dialog>

      <NewAddressDialog title={t('address_generation')}>
        <NewAddressGenerationWrapper>
          <IconWrapper>
            <Lottie
              loop={false}
              animationData={resultFeedbackSuccessAnimation}
            />
          </IconWrapper>
          <Typography fontFamily="primaryBold" type="subHeadline">
            {t('address_generation_success')}
          </Typography>
          <CustomInput
            value={createAddressName}
            onChangeText={setCreateAddressName}
            placeholder={t('common_address_name')}
            errorMessage={inputErrorMessage}
          />
          <Button
            onClick={handleAddressGenerateClick}
            title={t('common_continue_deposit')}
            disabled={!!inputErrorMessage}
          />
        </NewAddressGenerationWrapper>
      </NewAddressDialog>

      <ConfirmationDialog
        type={ConfirmationModalType.DELETE}
        title={t('delete_wallet_confirmation_title')}
        description={t('delete_wallet_confirmation_desc')}
        buttonText={t('common_delete')}
      />
    </MultiAddressViewContainer>
  );
};
