import { FC } from 'react';

import { CloseIcon, Typography } from '@libs/components';
import { screenSizes } from '@libs/theme';

import { useDimensions } from 'src/hooks';

import { CloseIconWrapper, RenderOptionHeaderWrapper } from './styles';

type BankOptionsHeaderProps = {
  closeModal: () => void;
  title: string;
};

const OptionsHeader: FC<BankOptionsHeaderProps> = ({ closeModal, title }) => {
  const { width } = useDimensions();

  if (width < screenSizes.tablet) {
    return null;
  }

  return (
    <RenderOptionHeaderWrapper>
      <Typography type="subHeadline" fontFamily="primaryBold">
        {title}
      </Typography>
      <CloseIconWrapper onClick={closeModal}>
        <CloseIcon />
      </CloseIconWrapper>
    </RenderOptionHeaderWrapper>
  );
};

export default OptionsHeader;
