import { useQuery } from '@apollo/client';
import { FeatureModuleEnum } from '@libs/backend-common';
import { Typography, useModal } from '@libs/components';
import { formatBalance } from '@libs/helpers';
import { CurrencyType } from '@libs/types';
import { useState } from 'react';
import { getCardsQuery, GetCardsType } from 'src/gql';
import {
  useApiResponse,
  useCardDetector,
  useDepositCard,
  useTranslation,
} from 'src/hooks';
import { useTheme } from 'styled-components';
import AddCardWarningModal from '../add-new-card-warning-modal/AddCardWarningModal';
import { CardDeleteConfirmation } from '../card-delete-confirmation/CardDeleteConfirmation';
import { CardOption } from '../card-option/CardOption';
import { FeatureRenderer } from '../feature-renderer/FeatureRenderer';
import { NewCard } from '../new-card/NewCard';
import {
  CustomDisclaimer,
  DepositCardAmountWrapper,
  NewCardTitleWrapper,
  OptionsWrapper,
} from './style';

type DepositCardOptionsProps = {
  depositCardAmountValue: string;
  currency: CurrencyType;
};
export const DepositCardOptions: React.FC<DepositCardOptionsProps> = ({
  depositCardAmountValue,
  currency,
}) => {
  const { t } = useTranslation();
  const { handleDepositCard, payWithCardLoadingState } = useDepositCard();
  const [shouldSaveNewCard, setShouldSaveNewCard] = useState(false);
  const { cardDetector } = useCardDetector();
  const { colors } = useTheme();
  const { data, loading } = useQuery(getCardsQuery);
  const { response } = useApiResponse({ data, loading });
  const [deleteCard, setDeleteCard] = useState<GetCardsType>();

  const {
    Dialog: WarningDialog,
    openModal: openWarningModal,
    closeModal: closeWarningModal,
  } = useModal();
  const { Dialog, openModal, closeModal } = useModal();

  const handlePayWithNewCard = () => {
    handleDepositCard({
      variables: {
        record: {
          asset: currency,
          amount: Number(depositCardAmountValue),
          shouldSaveCard: shouldSaveNewCard,
        },
      },
    });
  };

  const handleSaveToggle = () => {
    if (
      response?.getCards &&
      !shouldSaveNewCard &&
      response?.getCards.length >= 2
    ) {
      openModal();
    } else {
      setShouldSaveNewCard(!shouldSaveNewCard);
    }
  };

  const handleDeleteCardFromWarning = (card: GetCardsType) => {
    setDeleteCard(card);

    closeModal();

    openWarningModal();
  };

  return (
    <FeatureRenderer acceptedFeature={[FeatureModuleEnum.CARD_DEPOSIT]}>
      <DepositCardAmountWrapper>
        <Typography type="bodyButton" fontFamily="primaryRegular">
          {t('deposit_will_get')}
        </Typography>
        <Typography
          type="subHeadline"
          fontFamily="primaryMedium"
          color={colors.interactive.brandColor}
        >
          {formatBalance(depositCardAmountValue)}
        </Typography>
      </DepositCardAmountWrapper>
      {response?.getCards.length ? (
        <>
          <NewCardTitleWrapper>
            <Typography type="subHeadline" fontFamily="primaryBold">
              {t('deposit_saved_cards')}
            </Typography>
          </NewCardTitleWrapper>
          <OptionsWrapper>
            {response?.getCards.map((option, i) => {
              const { cardProvider, cardIcon } = cardDetector(
                option.cardMask as string
              );

              const handleCardPaymant = () => {
                handleDepositCard({
                  variables: {
                    record: {
                      asset: currency,
                      amount: Number(depositCardAmountValue),
                      cardId: Number(option.id),
                    },
                  },
                });
              };
              const handleDeleteCard = () => {
                handleDeleteCardFromWarning(option);
              };

              return (
                <CardOption
                  handleDeleteClick={handleDeleteCard}
                  onClick={handleCardPaymant}
                  key={i}
                  cardProvider={cardProvider}
                  cardIcon={cardIcon}
                  cardMask={option.cardMask}
                />
              );
            })}
          </OptionsWrapper>
        </>
      ) : null}

      <NewCardTitleWrapper>
        <Typography type="subHeadline" fontFamily="primaryBold">
          {t('deposit_new_card_title')}
        </Typography>
      </NewCardTitleWrapper>
      <NewCard
        isToggleChecked={shouldSaveNewCard}
        handleNewCardSaveToggle={handleSaveToggle}
        handlePayWithNewCard={handlePayWithNewCard}
      />

      <CustomDisclaimer
        description={t('deposit_card_disclaimer_first')}
        boldDescription={t('deposit_card_disclaimer_second')}
      />

      <Dialog title={t('deposit_save_cards_title')}>
        <AddCardWarningModal
          handleDeleteCardFromWarning={handleDeleteCardFromWarning}
        />
      </Dialog>

      <WarningDialog title={t('common_card_delete')}>
        <CardDeleteConfirmation
          selectedDeleteCard={deleteCard}
          closeModal={closeWarningModal}
        />
      </WarningDialog>
    </FeatureRenderer>
  );
};
