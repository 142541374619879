import { forwardRef, ForwardRefRenderFunction, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { handleExcelExport } from '@libs/helpers';
import { useDimensions } from '@libs/hooks';
import { screenSizes } from '@libs/theme';
import { RateTypes, Swap } from '@libs/types';
import { SwapDetails, Typography } from '@libs/components';

import { useTranslation } from 'src/hooks';
import { ExcelLogo } from 'src/assets';

import {
  ExportButton,
  MobileExportButton,
  MobileExportButtonWrapper,
  SwapsBySwapTaskContainer,
  SwapsBySwapTaskHeader,
} from './styles';

type SwapsBySwapTaskSectionProps = {
  originalAssetIcon?: JSX.Element | string;
  receivedAssetIcon?: JSX.Element | string;
  originalAssetCode?: string;
  receivedAssetCode?: string;
  rateType?: RateTypes;
  sentAmount?: string;
  waitingAmount?: string;
  receivedAmount?: string;
  swaps?: Swap[];
};

const Swaps: ForwardRefRenderFunction<
  HTMLDivElement,
  SwapsBySwapTaskSectionProps
> = (
  {
    swaps,
    originalAssetIcon,
    receivedAssetIcon,
    originalAssetCode,
    receivedAssetCode,
    rateType,
  },
  ref
) => {
  const { width } = useDimensions();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [searchParams] = useSearchParams();
  const swapTaskId = searchParams.get('id');

  if (!swaps?.length) return null;

  const tooltipText =
    rateType === RateTypes.FIXED
      ? t('swap_calculator_fixed_rate_desc')
      : t('swap_calculator_floating_rate_desc');

  const handleExcelExportBtnClick = async () => {
    setIsLoading(true);

    await handleExcelExport(
      'user-swap-history',
      process.env.GENERATE_EXCEL_API_URL,
      {
        swapTaskUuid: swapTaskId,
      }
    );
    setIsLoading(false);
  };

  return (
    <SwapsBySwapTaskContainer ref={ref}>
      <SwapsBySwapTaskHeader>
        <Typography
          type={width >= screenSizes.tabletL ? 'titleOne' : 'titleTwo'}
          fontFamily="primaryBold"
        >
          {t('swap_details_task_swaps_title')}
        </Typography>
        <ExportButton
          onClick={handleExcelExportBtnClick}
          isLoading={isLoading}
          icon={<img src={ExcelLogo} />}
          buttonType="secondary"
          size="sm"
          title={t('common_download')}
        />
        <MobileExportButtonWrapper>
          <MobileExportButton
            title={t('common_download')}
            onClick={handleExcelExportBtnClick}
            isLoading={isLoading}
            icon={<img src={ExcelLogo} />}
            buttonType="secondary"
          />
        </MobileExportButtonWrapper>
      </SwapsBySwapTaskHeader>
      {swaps?.map((swap, index) => (
        <SwapDetails
          key={index}
          swapStatus={swap.status}
          receivedAmount={swap?.calculatedData?.receivedAmount || 0}
          originalAmount={swap?.deposit?.amount || 0}
          originalAssetIcon={originalAssetIcon}
          destinationAssetIcon={receivedAssetIcon}
          originalAssetCode={originalAssetCode}
          receivedAssetCode={receivedAssetCode}
          date={swap.createdAt}
          rate={rateType}
          swapIdTitle={t('swap_details_id', { id: swap.uuid || '' })}
          sourceTitle={t('swap_details_source_title')}
          destinationTitle={t('swap_details_destination_title')}
          swapRateTypeTitle={t('swap_details_rate_title')}
          swapDateTitle={t('swap_details_date_title')}
          firstStepTitle={t('swap_progress_bar_first_title')}
          secondStepTitle={t('swap_progress_bar_second_title')}
          thirdStepTitle={t('swap_progress_bar_third_title')}
          fourthStepTitle={t('swap_progress_bar_fourth_title')}
          uuid={swap.uuid}
          buttonTextAfterCopying={t('common_copied')}
          buttonTextBeforeCopying={t('common_copy')}
          finishedTitle={t('status_finished')}
          rejectTitle={t('status_rejected')}
          tooltipText={tooltipText}
          swapDepositHashTitle={t('swap_deposit_hash_title')}
          swapWithdrawHashTitle={t('swap_withdraw_hash_title')}
          swapDepositHash={swap.deposit.hash}
          swapWithdrawHash={swap.withdrawal?.hash}
        />
      ))}
    </SwapsBySwapTaskContainer>
  );
};

export const SwapsBySwapTaskSection = forwardRef(Swaps);
