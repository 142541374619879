import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { NegativeIcon, PositiveIcon, Typography } from '@libs/components';

import { useTranslation } from 'src/hooks';
import { RateHistoryItem } from 'src/gql';
import { getUsdOrEurCurrencySymbol } from 'src/helpers';
import { BalanaceFinancialChart } from 'src/components';

import {
  BuyButton,
  Container,
  TextWrapper,
  AssetName,
  Img,
  ChartWrapper,
  IconAndChartWrapper,
  ChangesWrapper,
} from './styles';

export type PopularAssetBlockProps = {
  iconUrl?: string;
  assetCode?: string;
  changeRate?: string;
  cost?: string;
  data?: RateHistoryItem[];
};

export const PopularAssetBlock: React.FC<PopularAssetBlockProps> = ({
  iconUrl,
  assetCode,
  changeRate,
  cost,
  data,
}) => {
  const { colors } = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const filteredRate = changeRate?.replace('%', '');

  const hasPriceWentUp = Number(filteredRate) > 0;

  const color = hasPriceWentUp
    ? colors.text.success
    : colors.interactive.critical;

  const PositiveOrNegativeSign = hasPriceWentUp ? (
    <PositiveIcon />
  ) : (
    <NegativeIcon />
  );

  const formatedRate = hasPriceWentUp
    ? `${changeRate}`
    : changeRate?.replace('-', '');

  const handleButtonClick = () =>
    navigate(`/convert/BUY?selectedCurrency=${assetCode}`);

  const formatedPrice = t('all_assets_table_price', {
    currency: getUsdOrEurCurrencySymbol(),
    amount: cost || ' ',
  });

  const formatedData = data?.map((e) => ({
    ...e,
    amount: e.buyRate,
  }));

  const chartMargin = {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  };

  return (
    <Container>
      <IconAndChartWrapper>
        <Img src={iconUrl} />
        <ChartWrapper>
          <BalanaceFinancialChart
            surfaceColor={colors.interactive.secondaryDisabled}
            shouldHideAxisLines={true}
            data={formatedData}
            customMargin={chartMargin}
            shouldShowGraph={false}
            assetCode={assetCode}
            shouldHideTooltip
            shouldHideXAxis
            shouldHideYAxis
          />
        </ChartWrapper>
      </IconAndChartWrapper>
      <TextWrapper>
        <AssetName type="headline">{assetCode}</AssetName>
        <ChangesWrapper>
          {PositiveOrNegativeSign}
          <Typography color={color} type="bodyButton" fontFamily="primaryBold">
            {formatedRate}
          </Typography>
        </ChangesWrapper>
      </TextWrapper>
      <Typography type="subHeadline" fontFamily="primaryMedium">
        {formatedPrice}
      </Typography>
      <BuyButton onClick={handleButtonClick}>{t('dashboard_buy')}</BuyButton>
    </Container>
  );
};

export default PopularAssetBlock;
