import { FC } from 'react';

import { useTheme } from 'styled-components';

import { Typography } from '@libs/components';

import { FlexWrapper } from './styles';

type OptionsIconWrapperProps = {
  firstIcon: JSX.Element | string;
  secondIcon: JSX.Element | string;
};

export const OptionsIconWrapper: FC<OptionsIconWrapperProps> = ({
  firstIcon,
  secondIcon,
}) => {
  const { colors } = useTheme();

  return (
    <FlexWrapper>
      {typeof firstIcon === 'string' ? <img src={firstIcon} /> : firstIcon}
      <Typography color={colors.text.secondary} type="headline">
        /
      </Typography>
      {typeof secondIcon === 'string' ? <img src={secondIcon} /> : secondIcon}
    </FlexWrapper>
  );
};
