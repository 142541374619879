import { useEffect, useMemo } from 'react';

import { ThemeProvider } from 'styled-components';
// import TagManager from 'react-gtm-module';

import { detectLanguage } from '@libs/helpers';
import { ThemeMode } from '@libs/theme';
import { useLanguage } from '@libs/hooks';
import { useThemeModeToggler } from '@libs/components';
import { JurisdictionsEnum } from '@libs/backend-common';
import { theme, fontsByLang, darkTheme } from '@libs/theme';
import { DEFAULT_CURRENCY_SYMBOLS } from '@libs/types';

import {
  useApi,
  useThemeMode,
  useCurrencyConverter,
  useJurisdictionDetector,
  useAppDisclaimerContext,
} from 'src/hooks';
import {
  CurrencyProvider,
  LanguageProvider,
  ThemeModeProvider,
  ApiResponseProvider,
  AppDisclaimerProvider,
} from 'src/contexts';
import { Router } from 'src/pages';
import * as languages from 'src/languages';

import { GlobalStyles } from './index';

function ThemedApp() {
  const { selectedTheme, selectTheme } = useThemeMode();
  const { currentJurisdiction } = useJurisdictionDetector();
  const isJurisdictionUa = currentJurisdiction === JurisdictionsEnum.UA;

  const defaultLanguage = isJurisdictionUa ? languages.en : languages.ka;
  const defaultCurrency = DEFAULT_CURRENCY_SYMBOLS[currentJurisdiction];

  const languageState = useLanguage(
    languages,
    detectLanguage(languages, defaultLanguage, currentJurisdiction),
    currentJurisdiction
  );
  const apiResponseState = useApi();
  const currencyState = useCurrencyConverter(defaultCurrency);

  // Google tag Manager
  // useEffect(() => {
  //   const tagManagerId = process.env.GOOGLE_TAG_MANAGER_ID;
  //   const tagManagerArgs = {
  //     gtmId: tagManagerId,
  //   };
  //   if (INITIALIZE_TAG_ARGS && !!tagManagerArgs.gtmId) {
  //     TagManager.initialize(tagManagerArgs);
  //   }
  // }, []);

  useEffect(() => {
    if (!selectedTheme && isJurisdictionUa) {
      selectTheme(ThemeMode.dark);
    }
  }, [selectedTheme, isJurisdictionUa, selectTheme]);

  const finalTheme = useMemo(() => {
    let themeToApply = isJurisdictionUa ? darkTheme : {};

    if (selectedTheme === ThemeMode.dark) {
      themeToApply = darkTheme;
    } else if (selectedTheme === ThemeMode.light) {
      themeToApply = {};
    }

    return {
      ...theme,
      ...themeToApply,
      fonts: fontsByLang[languageState.selectedLanguageCode],
    };
  }, [languageState, isJurisdictionUa, selectedTheme]);

  useEffect(() => {
    const link = document.querySelector('link[rel="icon"]') as HTMLLinkElement;
    document.title = isJurisdictionUa ? 'Crexone' : 'Bitnet';
    link.href = isJurisdictionUa ? '/crexone.svg' : '/favicon.svg';
  }, [isJurisdictionUa]);

  const disclaimerProviderValue = useAppDisclaimerContext();

  return (
    <ThemeProvider theme={finalTheme}>
      <ApiResponseProvider value={apiResponseState}>
        <LanguageProvider value={languageState}>
          <CurrencyProvider value={currencyState}>
            <GlobalStyles />
            <AppDisclaimerProvider value={disclaimerProviderValue}>
              <Router />
            </AppDisclaimerProvider>
            <div id="react-portal" />
          </CurrencyProvider>
        </LanguageProvider>
      </ApiResponseProvider>
    </ThemeProvider>
  );
}

const App = () => {
  const { currentJurisdiction } = useJurisdictionDetector();
  const themeModeState = useThemeModeToggler(currentJurisdiction);

  return (
    <ThemeModeProvider value={themeModeState}>
      <ThemedApp />
    </ThemeModeProvider>
  );
};

export default App;
