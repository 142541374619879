import { Button } from '@libs/components';
import { DeleteIcon } from 'src/assets';
import styled from 'styled-components';

export const CardDeleteConfirmationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  padding: 3.2rem;

  ${({ theme }) => theme.media.laptop`

  width:50rem;
  `};
`;

export const CardMaskWrapper = styled.div`
  margin: 2rem 0 2.4rem;
`;

export const CancelButton = styled(Button)`
  margin-top: 1.2rem;
`;

export const CardIconWrapper = styled.div`
  width: 7.2rem;
  height: 7.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: ${({ theme }) => theme.colors.surface.tertiary};
  border-radius: ${({ theme }) => theme.borderRadius.highest};
  margin-bottom: 2rem;
`;

export const Icon = styled.img``;

export const DeleteIconWrapper = styled.div`
  width: 2rem;
  height: 2rem;
  background-color: ${({ theme }) => theme.colors.surface.rejectedBackground};
  border-radius: ${({ theme }) => theme.borderRadius.highest};
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0;
  right: 0;
`;
export const CustomDeleteIcon = styled(DeleteIcon)`
  width: 1.2rem;
  height: 1.2rem;
`;
