import { Disclaimer } from '@libs/components';
import styled from 'styled-components';

export const DepositCardAmountWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const OptionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.6rem;
`;

export const CustomDisclaimer = styled(Disclaimer)`
  border: 0;
  margin-top: 2rem;
`;

export const NewCardTitleWrapper = styled.div`
  margin: 2rem 0 1.6rem;
`;
