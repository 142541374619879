import styled from 'styled-components';

export const RenderOptionHeaderWrapper = styled.div`
  display: flex;
  padding: 2.6rem 0;
  text-align: center;
  border-bottom: 0.1rem solid ${({ theme }) => theme.colors.borders.default};
  justify-content: center;
  position: relative;
`;

export const CloseIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 3.2rem;
  height: 3.2rem;

  border: 0.1rem solid ${({ theme }) => theme.colors.borders.default};
  border-radius: ${({ theme }) => theme.borderRadius.highest};

  cursor: pointer;

  svg {
    height: initial;
    width: initial;
  }

  position: absolute;
  top: 2.4rem;
  right: 2.4rem;
`;
