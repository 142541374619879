import { CustomSvg } from '@libs/components';

const FiatCashOptions = () => {
  return (
    <CustomSvg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.8148 30.8152C22.9968 30.8152 29.6296 24.1824 29.6296 16.0004C29.6296 7.81837 22.9968 1.18555 14.8148 1.18555C6.63282 1.18555 0 7.81837 0 16.0004C0 24.1824 6.63282 30.8152 14.8148 30.8152Z"
        fill="#E7EFFF"
      />
      <path
        d="M29.6296 16.0004C29.6296 18.9305 28.7608 21.7947 27.1329 24.231C25.505 26.6673 23.1913 28.5662 20.4842 29.6875C17.7771 30.8088 14.7984 31.1021 11.9246 30.5305C9.0508 29.9589 6.41105 28.5479 4.33916 26.476C2.26728 24.4041 0.856302 21.7644 0.284669 18.8906C-0.286964 16.0168 0.00641907 13.038 1.12772 10.331C2.24901 7.62392 4.14787 5.31016 6.58415 3.68229C9.02043 2.05442 11.8847 1.18555 14.8148 1.18555C18.7439 1.18555 22.5122 2.74639 25.2905 5.5247C28.0688 8.30302 29.6296 12.0712 29.6296 16.0004Z"
        fill="#CFDFFF"
      />
      <path
        d="M2.07395 1.77778C2.56487 1.77778 2.96284 1.37981 2.96284 0.888889C2.96284 0.397969 2.56487 0 2.07395 0C1.58303 0 1.18506 0.397969 1.18506 0.888889C1.18506 1.37981 1.58303 1.77778 2.07395 1.77778Z"
        fill="#00C99F"
      />
      <path
        d="M31.1111 6.81489C31.602 6.81489 31.9999 6.41692 31.9999 5.926C31.9999 5.43508 31.602 5.03711 31.1111 5.03711C30.6201 5.03711 30.2222 5.43508 30.2222 5.926C30.2222 6.41692 30.6201 6.81489 31.1111 6.81489Z"
        fill="#FFA100"
      />
      <path
        d="M29.926 31.9985C30.4169 31.9985 30.8149 31.6005 30.8149 31.1096C30.8149 30.6187 30.4169 30.2207 29.926 30.2207C29.4351 30.2207 29.0371 30.6187 29.0371 31.1096C29.0371 31.6005 29.4351 31.9985 29.926 31.9985Z"
        fill="#00C99F"
      />
      <path
        d="M2.37033 31.1118C2.86125 31.1118 3.25922 30.7138 3.25922 30.2229C3.25922 29.732 2.86125 29.334 2.37033 29.334C1.87941 29.334 1.48145 29.732 1.48145 30.2229C1.48145 30.7138 1.87941 31.1118 2.37033 31.1118Z"
        fill="#FF4D69"
      />
      <path
        d="M30.332 15.9797C29.914 16.1489 29.5399 16.4106 29.2376 16.7452C28.9353 17.0798 28.7128 17.4786 28.5868 17.9115L28.4446 18.4508C28.4266 18.5124 28.3892 18.5664 28.3379 18.6049C28.2866 18.6433 28.2243 18.6641 28.1602 18.6641C28.096 18.6641 28.0337 18.6433 27.9824 18.6049C27.9311 18.5664 27.8937 18.5124 27.8757 18.4508L27.7216 17.9115C27.5956 17.4786 27.3731 17.0798 27.0709 16.7452C26.7686 16.4106 26.3944 16.1489 25.9765 15.9797C25.9212 15.9579 25.8738 15.9199 25.8403 15.8708C25.8069 15.8216 25.7891 15.7636 25.7891 15.7041C25.7891 15.6447 25.8069 15.5867 25.8403 15.5375C25.8738 15.4884 25.9212 15.4504 25.9765 15.4286C26.3944 15.2594 26.7686 14.9976 27.0709 14.663C27.3731 14.3284 27.5956 13.9297 27.7216 13.4967L27.852 12.9575C27.87 12.8959 27.9074 12.8419 27.9587 12.8034C28.01 12.7649 28.0723 12.7441 28.1365 12.7441C28.2006 12.7441 28.2629 12.7649 28.3142 12.8034C28.3655 12.8419 28.4029 12.8959 28.4209 12.9575L28.575 13.4967C28.701 13.9297 28.9235 14.3284 29.2257 14.663C29.528 14.9976 29.9022 15.2594 30.3202 15.4286C30.3759 15.4492 30.4242 15.4861 30.4587 15.5346C30.4932 15.583 30.5123 15.6407 30.5136 15.7001C30.5149 15.7595 30.4982 15.818 30.4658 15.8679C30.4335 15.9177 30.3868 15.9567 30.332 15.9797Z"
        fill="#FFA100"
      />
      <path
        d="M24.2964 8.29651V11.2595H0.776367C1.28 9.76926 2.01816 8.36895 2.96303 7.11133H23.1112C23.4255 7.11133 23.727 7.2362 23.9492 7.45846C24.1715 7.68073 24.2964 7.98218 24.2964 8.29651Z"
        fill="#323FD4"
      />
      <path
        d="M24.2963 10.6657V23.7027C24.2963 24.017 24.1715 24.3185 23.9492 24.5407C23.7269 24.763 23.4255 24.8879 23.1111 24.8879H2.96299C1.32437 22.7031 0.321558 20.1078 0.0653457 17.3888C-0.190866 14.6698 0.309489 11.9329 1.51114 9.48047H23.1111C23.4255 9.48047 23.7269 9.60534 23.9492 9.8276C24.1715 10.0499 24.2963 10.3513 24.2963 10.6657Z"
        fill="#4E6AFF"
      />
      <path
        d="M10.9628 22.8159C13.7447 22.8159 15.9999 20.2954 15.9999 17.1863C15.9999 14.0771 13.7447 11.5566 10.9628 11.5566C8.18094 11.5566 5.92578 14.0771 5.92578 17.1863C5.92578 20.2954 8.18094 22.8159 10.9628 22.8159Z"
        fill="white"
      />
      <path
        d="M24.2965 10.6657V12.4434C23.5106 12.4434 22.757 12.1313 22.2013 11.5756C21.6457 11.0199 21.3335 10.2663 21.3335 9.48047H23.1113C23.4256 9.48047 23.7271 9.60534 23.9493 9.8276C24.1716 10.0499 24.2965 10.3513 24.2965 10.6657Z"
        fill="#8FA2FF"
      />
      <path
        d="M24.2965 21.9258V23.7036C24.2965 24.0179 24.1716 24.3193 23.9493 24.5416C23.7271 24.7639 23.4256 24.8887 23.1113 24.8887H21.3335C21.3335 24.1029 21.6457 23.3493 22.2013 22.7936C22.757 22.2379 23.5106 21.9258 24.2965 21.9258Z"
        fill="#8FA2FF"
      />
      <path
        d="M24.2962 30.2214C27.2417 30.2214 29.6296 27.8335 29.6296 24.888C29.6296 21.9425 27.2417 19.5547 24.2962 19.5547C21.3507 19.5547 18.9629 21.9425 18.9629 24.888C18.9629 27.8335 21.3507 30.2214 24.2962 30.2214Z"
        fill="#FFA100"
      />
      <path
        d="M24.2963 24.2964C24.1392 24.2964 23.9884 24.2339 23.8773 24.1228C23.7662 24.0117 23.7037 23.8609 23.7037 23.7038C23.7037 23.5466 23.7662 23.3959 23.8773 23.2847C23.9884 23.1736 24.1392 23.1112 24.2963 23.1112H25.4815C25.6387 23.1112 25.7894 23.0487 25.9005 22.9376C26.0117 22.8265 26.0741 22.6758 26.0741 22.5186C26.0741 22.3614 26.0117 22.2107 25.9005 22.0996C25.7894 21.9884 25.6387 21.926 25.4815 21.926H24.8889V21.6297C24.8889 21.4725 24.8265 21.3218 24.7154 21.2107C24.6042 21.0995 24.4535 21.0371 24.2963 21.0371C24.1392 21.0371 23.9884 21.0995 23.8773 21.2107C23.7662 21.3218 23.7037 21.4725 23.7037 21.6297V22.0297C23.3098 22.1705 22.9781 22.4457 22.767 22.8069C22.556 23.1682 22.4791 23.5923 22.5499 24.0046C22.6207 24.4169 22.8346 24.7911 23.154 25.0612C23.4735 25.3314 23.878 25.4802 24.2963 25.4816C24.4535 25.4816 24.6042 25.544 24.7154 25.6551C24.8265 25.7663 24.8889 25.917 24.8889 26.0741C24.8889 26.2313 24.8265 26.382 24.7154 26.4932C24.6042 26.6043 24.4535 26.6667 24.2963 26.6667H23.1111C22.954 26.6667 22.8033 26.7292 22.6921 26.8403C22.581 26.9514 22.5186 27.1022 22.5186 27.2593C22.5186 27.4165 22.581 27.5672 22.6921 27.6784C22.8033 27.7895 22.954 27.8519 23.1111 27.8519H23.7037V28.1482C23.7037 28.3054 23.7662 28.4561 23.8773 28.5672C23.9884 28.6784 24.1392 28.7408 24.2963 28.7408C24.4535 28.7408 24.6042 28.6784 24.7154 28.5672C24.8265 28.4561 24.8889 28.3054 24.8889 28.1482V27.7482C25.2829 27.6074 25.6146 27.3322 25.8256 26.971C26.0367 26.6098 26.1136 26.1857 26.0428 25.7733C25.972 25.361 25.7581 24.9869 25.4386 24.7167C25.1192 24.4465 24.7147 24.2977 24.2963 24.2964Z"
        fill="white"
      />
      <path d="M13.9259 9.48047H8V24.8879H13.9259V9.48047Z" fill="#8FA2FF" />
      <path d="M13.9259 7.11133H8V9.4817H13.9259V7.11133Z" fill="#4E6AFF" />
      <path
        d="M4.1482 17.777H2.37042C2.21326 17.777 2.06253 17.7145 1.9514 17.6034C1.84027 17.4923 1.77783 17.3416 1.77783 17.1844C1.77783 17.0272 1.84027 16.8765 1.9514 16.7654C2.06253 16.6542 2.21326 16.5918 2.37042 16.5918H4.1482C4.30537 16.5918 4.4561 16.6542 4.56723 16.7654C4.67836 16.8765 4.7408 17.0272 4.7408 17.1844C4.7408 17.3416 4.67836 17.4923 4.56723 17.6034C4.4561 17.7145 4.30537 17.777 4.1482 17.777Z"
        fill="white"
      />
      <path
        d="M19.5554 17.777H17.7777C17.6205 17.777 17.4698 17.7145 17.3586 17.6034C17.2475 17.4923 17.1851 17.3416 17.1851 17.1844C17.1851 17.0272 17.2475 16.8765 17.3586 16.7654C17.4698 16.6542 17.6205 16.5918 17.7777 16.5918H19.5554C19.7126 16.5918 19.8633 16.6542 19.9745 16.7654C20.0856 16.8765 20.148 17.0272 20.148 17.1844C20.148 17.3416 20.0856 17.4923 19.9745 17.6034C19.8633 17.7145 19.7126 17.777 19.5554 17.777Z"
        fill="white"
      />
      <path
        d="M10.48 3.23751C10.062 3.40672 9.68782 3.66845 9.38555 4.00305C9.08328 4.33765 8.86078 4.73641 8.73477 5.16936L8.59255 5.70862C8.5746 5.77017 8.53717 5.82423 8.48588 5.86269C8.43459 5.90116 8.37222 5.92195 8.30811 5.92195C8.244 5.92195 8.18162 5.90116 8.13033 5.86269C8.07904 5.82423 8.04161 5.77017 8.02366 5.70862L7.86959 5.16936C7.74358 4.73641 7.52108 4.33765 7.21881 4.00305C6.91654 3.66845 6.54236 3.40672 6.1244 3.23751C6.06914 3.21567 6.02171 3.17771 5.9883 3.12857C5.95488 3.07943 5.93701 3.02138 5.93701 2.96195C5.93701 2.90253 5.95488 2.84447 5.9883 2.79534C6.02171 2.7462 6.06914 2.70824 6.1244 2.6864C6.54236 2.51719 6.91654 2.25545 7.21881 1.92085C7.52108 1.58625 7.74358 1.18749 7.86959 0.754546L7.99996 0.215286C8.01791 0.153741 8.05534 0.0996782 8.10662 0.0612124C8.15791 0.0227466 8.22029 0.00195312 8.2844 0.00195312C8.34851 0.00195312 8.41089 0.0227466 8.46218 0.0612124C8.51347 0.0996782 8.55089 0.153741 8.56885 0.215286L8.72292 0.754546C8.84893 1.18749 9.07143 1.58625 9.3737 1.92085C9.67597 2.25545 10.0501 2.51719 10.4681 2.6864C10.5239 2.70702 10.5721 2.74395 10.6066 2.79237C10.6411 2.8408 10.6603 2.89848 10.6615 2.95792C10.6628 3.01736 10.6462 3.07581 10.6138 3.12568C10.5814 3.17554 10.5348 3.2145 10.48 3.23751Z"
        fill="#FFA100"
      />
    </CustomSvg>
  );
};

export default FiatCashOptions;
