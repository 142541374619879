import { DropdownIcon, TransparentButton, Typography } from '@libs/components';
import { DeleteIcon } from 'src/assets';
import { useTheme } from 'styled-components';
import {
  CustomDeleteIcon,
  DropDownIconContainer,
  DropDownIconWrapper,
  FlexStartDeleteIcon,
  Icon,
  OptionContainer,
  OptionInfoWrapper,
  OptionLabelsWrapper,
  OptionWrapper,
} from './styles';

type CardOptionsProps = {
  cardIcon?: string;
  cardProvider?: string;

  cardMask?: string;
  onClick?: () => void;
  handleDeleteClick?: () => void;
};

export const CardOption: React.FC<CardOptionsProps> = ({
  cardIcon,
  cardProvider,
  cardMask,
  onClick,
  handleDeleteClick,
}) => {
  const ableToDeleteAndPay = handleDeleteClick && onClick;
  const { colors } = useTheme();
  const bgColor = !ableToDeleteAndPay
    ? colors.surface.tertiary
    : colors.surface.default;

  return (
    <OptionContainer backgroundColor={bgColor}>
      {ableToDeleteAndPay && (
        <FlexStartDeleteIcon onClick={handleDeleteClick}>
          <CustomDeleteIcon />
        </FlexStartDeleteIcon>
      )}
      <OptionWrapper onClick={onClick} showLeftBorder={!!ableToDeleteAndPay}>
        <OptionInfoWrapper>
          <Icon src={cardIcon} />

          <OptionLabelsWrapper>
            <Typography type="caption" fontFamily="primaryRegular">
              {cardProvider}
            </Typography>
            <Typography type="subHeadline" fontFamily="primaryMedium">
              {cardMask}
            </Typography>
          </OptionLabelsWrapper>
        </OptionInfoWrapper>

        {!(handleDeleteClick && !onClick) && (
          <DropDownIconWrapper>
            <DropDownIconContainer>
              <DropdownIcon />
            </DropDownIconContainer>
          </DropDownIconWrapper>
        )}

        {handleDeleteClick && !onClick && (
          <TransparentButton onClick={handleDeleteClick}>
            <DeleteIcon />
          </TransparentButton>
        )}
      </OptionWrapper>
    </OptionContainer>
  );
};
