import { Link, Typography, OptionList, EmptyState } from '@libs/components';
import styled from 'styled-components';
import Copy from '../copy/Copy';

import Table from '../table/Table';

type TransactionsType = {
  hasTransactions: boolean;
};

type DataTextProps = {
  shouldCopyButtonHide?: boolean;
};
export const TransactionTableContainer = styled.div<TransactionsType>`
  height: ${({ hasTransactions }) => (!hasTransactions ? '100%' : 'initial')};
`;

export const TableWrapper = styled.div`
  display: none;

  ${({ theme }) => theme.media.laptop`
     display: flex;
  `};
`;

export const WideTable = styled(Table)`
  td {
    ${({ theme }) => theme.media.laptop`
      padding: 0 0.5rem;
  `};

    &:first-child {
      ${({ theme }) => theme.media.laptop`
      padding: 0 2.4rem;
  `};
    }
  }

  th {
    ${({ theme }) => theme.media.laptop`
      padding: 0 0.5rem;
  `};

    &:first-child {
      ${({ theme }) => theme.media.laptop`
      padding: 0 2.4rem;
  `};
    }
  }
`;

export const MobileTableWrapper = styled.div`
  ${({ theme }) => theme.media.mobileL`
    padding: 0 1.1rem;
  `};
`;

export const MobileTable = styled(Table)`
  display: flex;

  td {
    &:not(:first-child):not(:last-child) {
      padding: 0.5rem;
    }

    &:first-child {
      padding-left: 1rem;
      padding-right: 0;
    }

    &:last-child {
      padding-right: 1rem;
      padding-left: 0;
    }
  }

  ${({ theme }) => theme.media.laptop`
     display: none;
  `};
`;

export const EmptyStateWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 0 2rem;

  ${({ theme }) => theme.media.tablet`
    margin-top:-2rem;
  `};
`;

export const CustomEmpyState = styled(EmptyState)`
  max-width: 60rem;
`;

export const TransactionDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2.8rem 2.6rem 3.2rem;
  max-height: 50rem;

  ${({ theme }) => theme.media.tablet`
   max-height: initial;
  `};

  ${({ theme }) => theme.media.laptop`
    max-width: 50rem;
    padding: 2.8rem 2.6rem 2.4rem;
    min-width: 42rem;
  `};
`;

export const AssetIcon = styled.img`
  width: 3rem;
  height: 3rem;
`;

export const StatusWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 3rem;
  margin-bottom: 2.7rem;
  border-radius: ${({ theme }) => theme.borderRadius.highL};
  background-color: ${({ theme }) =>
    theme.colors.interactive.secondaryDisabled};
`;

export const CustomOptionList = styled(OptionList)`
  width: 100%;
  padding: 0;
  margin-bottom: 0;
  border: none;
  .text-wrapper {
    width: min-content;
  }
  .description {
    width: max-content;
  }
`;

export const DescriptionContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const CustomLink = styled(Link)`
  text-decoration: underline;
  text-decoration-color: ${({ theme }) => theme.colors.borders.pressed};
`;

export const CustomCopyButton = styled(Copy)`
  margin-left: 1rem;
`;

export const DataText = styled(Typography)<DataTextProps>`
  width: ${({ shouldCopyButtonHide }) =>
    shouldCopyButtonHide ? '100%' : '87%'};
  word-break: break-word;
`;
