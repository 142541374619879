import styled, { css } from 'styled-components';
import { Disclaimer, Radio } from '@libs/components';

type WithdrawFiatContainerProps = {
  hasPadding: boolean;
};

export const WithdrawFiatContainer = styled.div<WithdrawFiatContainerProps>`
  ${({ theme, hasPadding }) =>
    hasPadding &&
    css`
      padding: 2.8rem 2.2rem 3.5rem;

      ${theme.media.tablet`
        padding: 2.8rem 3.7rem 3.5rem;
      `}
    `}
`;

export const CustomRadio = styled(Radio)`
  border: none;
  border-radius: 0;
  overflow: visible;
  height: 100%;
  .radio-title {
    text-align: left;
  }

  .radio-icon {
    width: unset;
  }

  .radio-content {
    gap: 1.2rem;
  }
`;

export const CustomCurrencyTogglerWrapper = styled.div`
  margin: 1.2rem 0 1.5rem;
`;

export const StyledDisclaimer = styled(Disclaimer)`
  border: none;

  margin-bottom: 2rem;

  background-color: ${({ theme }) => theme.colors.surface.interactiveDefault};
`;

export const CustomInputRadiusContainer = styled.div`
  margin: 1.2rem 0 3.2rem;
`;
export const InputWrapper = styled.div`
  margin: 2rem 0;
`;

export const CurrencyImg = styled.img`
  height: 3.2rem;
  width: 3.2rem;
`;
