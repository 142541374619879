import { PropsWithChildren } from 'react';

import { JurisdictionsEnum } from '@libs/backend-common';
import { ThemeToggler } from '@libs/components';
import { AppLangCode } from '@libs/types';
import { useAssetUrlByJurisdiction } from '@libs/hooks';
import { getAssetUrl } from '@libs/helpers';

import {
  useAppDisclaimer,
  useJurisdictionDetector,
  useThemeMode,
  useTranslation,
} from 'src/hooks';
import {
  bitnetLogo,
  bitnetLogoSmall,
  CrexoneLogo,
  CrexoneMobileLogo,
} from 'src/assets';

import { TitleWrapper } from '../auth-page-layout/styles';
import PageHeading from '../page-heading/PageHeading';
import { JurisdictionRenderer } from '../jurisdiction-renderer/JurisdictionRenderer';
import MobileAppDisclaimer from '../mobile-app-disclaimer/MobileAppDisclaimer';

import {
  BannerForEu,
  BannerFOrGe,
  ChildrenWrapper,
  Container,
  ContentWrapper,
  CustomAuthHeader,
  ThemeTogglerWrapper,
  TitleAndContentWrapper,
} from './styles';

type WebsitePreviewLayoutProps = PropsWithChildren<{
  heading?: string;

  isHeadingPrimary?: boolean;
  isHeadingCenter?: boolean;
  highlightedHeading?: string;

  headerButtonTitle?: string;
  onHeaderButtonClick?: () => void;
  className?: string;
  hasBanner?: boolean;
  hasReferralCode?: boolean;
}>;

const registerBannerForGe = {
  [AppLangCode.KA]: getAssetUrl('register-banner-ka.webp'),
  [AppLangCode.EN]: getAssetUrl('register-banner-en.webp'),
  [AppLangCode.UA]: getAssetUrl('register-banner-ua.webp'),
  [AppLangCode.RU]: getAssetUrl('register-banner-ru.webp'),
};

const WebsitePreviewLayout: React.FC<WebsitePreviewLayoutProps> = ({
  children,
  heading,
  highlightedHeading,
  isHeadingPrimary = true,
  isHeadingCenter = false,
  onHeaderButtonClick,
  headerButtonTitle,
  className,
  hasBanner = true,
  hasReferralCode = false,
}) => {
  const { changeLanguage, selectedLanguageCode } = useTranslation();
  const { selectTheme, selectedTheme } = useThemeMode();

  const { currentJurisdiction } = useJurisdictionDetector();
  const isJurisdictionUa = currentJurisdiction === JurisdictionsEnum.UA;
  const isJurisdictionGe = currentJurisdiction === JurisdictionsEnum.GE;

  const registerBannerForEu = useAssetUrlByJurisdiction('register-banner.png');

  const bannerBackground = isJurisdictionGe
    ? registerBannerForGe[selectedLanguageCode]
    : registerBannerForEu;

  const { isAppDisclaimerVisible } = useAppDisclaimer();
  const shouldShowAppDisclaimer = isAppDisclaimerVisible && !hasReferralCode;

  return (
    <>
      <JurisdictionRenderer acceptedJurisdictions={[JurisdictionsEnum.GE]}>
        {shouldShowAppDisclaimer && <MobileAppDisclaimer />}
      </JurisdictionRenderer>
      <CustomAuthHeader
        isAppDisclaimerVisible={shouldShowAppDisclaimer}
        jurisdictionId={currentJurisdiction}
        isJurisdictionUa={isJurisdictionUa}
        changeLanguage={changeLanguage}
        selectedLanguage={selectedLanguageCode}
        logo={isJurisdictionUa ? CrexoneLogo : bitnetLogo}
        mobileLogo={isJurisdictionUa ? CrexoneMobileLogo : bitnetLogoSmall}
        onButtonClick={onHeaderButtonClick}
        buttonTitle={headerButtonTitle}
        themeToggler={
          isJurisdictionUa ? (
            <ThemeTogglerWrapper>
              <ThemeToggler
                onThemeToggle={selectTheme}
                activeTheme={selectedTheme}
              />
            </ThemeTogglerWrapper>
          ) : undefined
        }
      />
      <Container>
        <ContentWrapper>
          <TitleAndContentWrapper className={className}>
            <TitleWrapper isHeadingCenter={isHeadingCenter}>
              <PageHeading
                text={heading}
                isPrimary={isHeadingPrimary}
                highlightedText={highlightedHeading}
              />
            </TitleWrapper>
            <ChildrenWrapper>{children}</ChildrenWrapper>
          </TitleAndContentWrapper>
        </ContentWrapper>
        <JurisdictionRenderer
          acceptedJurisdictions={[JurisdictionsEnum.GE, JurisdictionsEnum.EU]}
        >
          {hasBanner &&
            (isJurisdictionGe ? (
              <BannerFOrGe BannerBackground={bannerBackground} />
            ) : (
              <BannerForEu BannerBackground={bannerBackground} />
            ))}
        </JurisdictionRenderer>
      </Container>
    </>
  );
};
export default WebsitePreviewLayout;
