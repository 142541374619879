import { TransparentButton } from '@libs/components';
import styled from 'styled-components';

export const NewCardButton = styled(TransparentButton)`
  width: calc(100% + 0.2rem);
  margin-top: -0.1rem;
  margin-right: -0.1rem;
  border-radius: ${({ theme }) => theme.borderRadius.high};
  border: 0.1rem solid ${({ theme }) => theme.colors.borders.default};
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.colors.surface.tertiary};
  padding: 1.6rem;
`;

export const NewCardLabelsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-left: 1.6rem;
`;
export const OptionTitle = styled.div``;

export const NewCardInfoWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const DropDownIconWrapper = styled.div`
  transform: rotate(-90deg);
  width: 2.6rem;
  height: 2.6rem;
`;

export const DropDownIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
`;

export const NewCardContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid ${({ theme }) => theme.colors.borders.default};
  border-radius: ${({ theme }) => theme.borderRadius.high};
`;

export const NewCardToggleWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 0.9rem 1.6rem;
`;
