import { Environment } from '@libs/types';
import { JurisdictionsEnum } from '@libs/backend-common';

import { GTMEventNames } from 'src/types';
import { DEFAULT_JURISDICTION_ID } from 'src/constants';

export const pushGTMEvent = (
  eventName: GTMEventNames,
  eventObject?: Record<string, unknown>
) => {
  if (
    process.env.ENVIRONMENT === Environment.dev ||
    JurisdictionsEnum.GE !== DEFAULT_JURISDICTION_ID ||
    typeof eventObject !== 'object'
  )
    return;

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: eventName,
    ...eventObject,
  });
};
