import styled from 'styled-components';

import { Disclaimer, SelectModal } from '@libs/components';

export const BeneficiaryContainer = styled.div`
  border-radius: ${({ theme }) => theme.borderRadius.mediumHigh};
  border: 1px solid ${({ theme }) => theme.colors.borders.default};
  padding: 1.6rem;
  margin-bottom: 1.8rem;
`;

export const DepositBankWireTitle = styled.div`
  display: flex;
  align-items: center;
  width: fit-content;
  margin-left: 1.6rem;
  padding: 0 1.6rem;
  height: 5.2rem;
  background-color: ${({ theme }) => theme.colors.background.light};
  border-top: 1px solid ${({ theme }) => theme.colors.borders.default};
  border-left: 1px solid ${({ theme }) => theme.colors.borders.default};
  border-right: 1px solid ${({ theme }) => theme.colors.borders.default};
  border-top-left-radius: ${({ theme }) => theme.borderRadius.medium};
  border-top-right-radius: ${({ theme }) => theme.borderRadius.medium};
`;
export const BankIconContainer = styled.div`
  margin-right: 1.3rem;
`;
export const KYCDisclaimer = styled(Disclaimer)`
  margin-bottom: 1.6rem;
`;

export const CustomSelectModal = styled(SelectModal)`
  ${({ theme }) => theme.media.tablet`
      .select-option {
          padding: 1.6rem;
          height: unset;
       }
      .separator {  
          width: 90%;
          margin:0 auto;
      }
`}
`;

export const OptionsWrapper = styled.div`
  padding: 1.6rem 2.2rem;
  border-top: 0.1rem solid ${({ theme }) => theme.colors.borders.default};
  border-bottom: 0.1rem solid ${({ theme }) => theme.colors.borders.default};
  box-shadow: ${({ theme }) => theme.shadows.lg};
`;

export const RequisiteNameWrapper = styled.div`
  display: flex;
  text-align: center;
  padding: 1.2rem;
  border-radius: ${({ theme }) => theme.borderRadius.high};
  background-color: ${({ theme }) => theme.colors.surface.interactiveDefault};
  margin-bottom: 1.2rem;

  ${({ theme }) => theme.media.laptop`
      margin-bottom: 1.6rem;
  `}
`;

export const ContentWrapper = styled.div`
  padding: 1.6rem 2.2rem;

  ${({ theme }) => theme.media.laptop`
    padding:2rem 3.6rem;
  `}
`;
