import { object, string } from 'yup';

import { RegexValidators } from '@libs/validations';
export { loginFormValidation } from '@libs/validations';
import { getJurisdictionBaseUrl } from '@libs/helpers';

import { ValidationErrorMessages } from './types';

export const bitnetLoginFormValidation = () => {
  return object().shape({
    email: string()
      .required(ValidationErrorMessages.EMAIL_OR_PHONE)
      .test(
        'emailOrPhoneFormat',
        ValidationErrorMessages.EMAIL_OR_PHONE_INVALID,
        (value) => {
          if (!value) return false;
          const { isGlobal } = getJurisdictionBaseUrl();

          if (!isGlobal) {
            return (
              string()
                .matches(RegexValidators.IS_EMAIL_VALID)
                .isValidSync(value) ||
              string()
                .matches(RegexValidators.GE_PHONE_NUMBER)
                .isValidSync(value)
            );
          } else {
            return string()
              .matches(RegexValidators.IS_EMAIL_VALID)
              .isValidSync(value);
          }
        }
      ),
    password: string()
      .required(ValidationErrorMessages.PASSWORD)
      .min(8, ValidationErrorMessages.PASSWORD_LENGTH),
  });
};
