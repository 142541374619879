import { FC, useState } from 'react';
import { useTheme } from 'styled-components';
import { useQuery } from '@apollo/client';

import { roundDecimal } from '@libs/helpers';
import { Button, GelIcon, Typography } from '@libs/components';
import { FeatureModuleEnum, JurisdictionsEnum } from '@libs/backend-common';
import { meQuery, FrStatuses } from '@libs/graphql';

import { CurrencyType } from 'src/constants';
import { useTranslation, useDepositCard, useApiResponse } from 'src/hooks';
import {
  Disclaimer,
  CurrencyInput,
  FeatureRenderer,
  JurisdictionRenderer,
} from 'src/components';

import {
  AmountInputContainer,
  DepositCardContainer,
  DisclaimerContainer,
  CustomTypography,
} from './style';

type DepositCardProps = {
  currency: CurrencyType | undefined;
  changeCurrencyTogglerValue: (val: CurrencyType) => void;
  isDigitalPay?: boolean;
  handleNextStep?: () => void;
  setDepositCardAmountValue?: (val: string) => void;
};

const DEPOSIT_FEE_PERCENTAGE = 0.02;

export const DepositCard: FC<DepositCardProps> = ({
  currency,
  changeCurrencyTogglerValue,
  isDigitalPay,
  handleNextStep,
  setDepositCardAmountValue,
}) => {
  const { t } = useTranslation();
  const { colors } = useTheme();
  const [amount, setAmount] = useState<string>('0.00');

  const { handleDepositCard, payWithCardLoadingState } = useDepositCard();
  const meRes = useQuery(meQuery);
  const { response: meResponse } = useApiResponse(meRes);
  const isUserVerified = meResponse?.me.frStatus === FrStatuses.VERIFIED;

  const GEL_OBJECT = {
    label: CurrencyType.GEL,
    value: CurrencyType.GEL,
    isCrypto: false,
    isStable: false,
    icon: (
      <GelIcon
        backgroundColor={colors.text.default}
        color={colors.text.interactive}
      />
    ),
  };

  const [sel, setSel] = useState<string | number>(currency || '');

  const calculatedAmountWithoutPrecision =
    +amount + +amount * DEPOSIT_FEE_PERCENTAGE;
  const receivedAmount = roundDecimal(calculatedAmountWithoutPrecision, 2);

  const handleCurrencySelectValue = (val: string | number) => {
    setSel(val);
    changeCurrencyTogglerValue(val as CurrencyType);
  };
  const handleCurrencyInputValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAmount(e.target.value);
  };

  const handlePayWithCardMutation = () => {
    if (amount.length && !isDigitalPay) {
      setDepositCardAmountValue?.(amount);
      handleNextStep?.();
    } else {
      handleDepositCard({
        variables: {
          record: {
            amount: +amount,
            asset: currency,
            isCardless: true,
          },
        },
      });
    }
  };

  const isSubmitBtnDisabled = !Number(amount) || !amount.length;

  const handleInputFocus = () => {
    setAmount('');
  };

  return (
    <FeatureRenderer acceptedFeature={[FeatureModuleEnum.CARD_DEPOSIT]}>
      <DepositCardContainer data-testid="depositCardContainer">
        <Typography
          type="subHeadline"
          fontFamily="primaryBold"
          color={colors.text.default}
        >
          {t('select_amount')}
        </Typography>
        <AmountInputContainer>
          <CurrencyInput
            name="depositCard"
            inputOnChange={handleCurrencyInputValue}
            selectedValue={sel}
            onChange={handleCurrencySelectValue}
            options={[GEL_OBJECT]}
            inputPlaceHolder={t('enter_amount')}
            inputValue={amount}
            onFocus={handleInputFocus}
          />
          <CustomTypography
            type="caption"
            fontFamily="primary"
            color={colors.text.default}
          >
            {t('total_amount', {
              amount: receivedAmount,
              currency: receivedAmount ? sel.toString() : '',
            })}
          </CustomTypography>
        </AmountInputContainer>
        <DisclaimerContainer>
          {!isDigitalPay && (
            <Disclaimer description={t('disclaimer_interval')} />
          )}
        </DisclaimerContainer>
        <JurisdictionRenderer acceptedJurisdictions={[JurisdictionsEnum.GE]}>
          {!isUserVerified && (
            <DisclaimerContainer>
              <Disclaimer description={t('kyc_operation_disclaimer')} />
            </DisclaimerContainer>
          )}
        </JurisdictionRenderer>

        <Button
          isLoading={payWithCardLoadingState}
          onClick={handlePayWithCardMutation}
          disabled={isSubmitBtnDisabled}
          title={t('common_continue')}
        />
      </DepositCardContainer>
    </FeatureRenderer>
  );
};

export default DepositCard;
