import { DropdownIcon, Toggle, Typography } from '@libs/components';
import { FiatCashOptions } from 'src/assets';
import { useTranslation } from 'src/hooks';
import {
  DropDownIconContainer,
  DropDownIconWrapper,
  NewCardInfoWrapper,
  NewCardLabelsWrapper,
  NewCardContainer,
  NewCardButton,
  NewCardToggleWrapper,
} from './styles';

type NewCardProps = {
  isToggleChecked: boolean;
  handleNewCardSaveToggle: () => void;
  handlePayWithNewCard: () => void;
};
export const NewCard: React.FC<NewCardProps> = ({
  isToggleChecked,
  handleNewCardSaveToggle,
  handlePayWithNewCard,
}) => {
  const { t } = useTranslation();

  return (
    <NewCardContainer>
      <NewCardButton onClick={handlePayWithNewCard}>
        <NewCardInfoWrapper>
          <FiatCashOptions />

          <NewCardLabelsWrapper>
            <Typography type="subHeadline" fontFamily="primaryMedium">
              {t('deposit_use_different_card')}
            </Typography>
          </NewCardLabelsWrapper>
        </NewCardInfoWrapper>
        <DropDownIconWrapper>
          <DropDownIconContainer>
            <DropdownIcon />
          </DropDownIconContainer>
        </DropDownIconWrapper>
      </NewCardButton>

      <NewCardToggleWrapper>
        <Typography type="bodyButton" fontFamily="primaryMedium">
          {t('deposit_save_card')}
        </Typography>
        <Toggle onChange={handleNewCardSaveToggle} checked={isToggleChecked} />
      </NewCardToggleWrapper>
    </NewCardContainer>
  );
};
