export const BOTTOM_TAB_HEIGHT = '7rem';

export type DashboardNavItemType = {
  title: string;
  route: string;
  icon: JSX.Element;
  activeIcon?: JSX.Element;
  isNew?: boolean;
  shouldHide?: boolean;
};
