import { useState, useRef, useCallback } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useTheme } from 'styled-components';

// import { Environment } from '@libs/types';
import { useOutsideClick } from '@libs/hooks';
import { JurisdictionsEnum } from '@libs/backend-common';
import {
  TransparentButton,
  Typography,
  ThemeToggler,
  SOCIAL_NETWORK_URLS,
  HeaderChat,
} from '@libs/components';

import {
  // ChatIcon,
  // NotificationIcon,
  bitnetLogo,
  AccountIcon,
  CrexoneLogo,
  CrexoneLogoBlack,
  TelegramIcon,
  ChatIcon,
} from 'src/assets';

import {
  useNavigationItems,
  useTranslation,
  usePageNameDetector,
  useTicketLiveData,
  useJurisdictionDetector,
  useThemeMode,
  useRenderObjectByTheme,
  useAssets,
  useAppDisclaimer,
} from 'src/hooks';

import AccountDropdown from '../account-dropdown/Account-Dropdown';
// import NotificationsDropdown from '../notifications/NotificationsDropdown';

import { JurisdictionRenderer } from '../jurisdiction-renderer/JurisdictionRenderer';
import {
  Container,
  Wrapper,
  BitnetLogo,
  HomePage,
  TransactionsWrapper,
  PageNameWrapper,
  CustomButton,
  IconWrapper,
  AccButton,
  JustifiedContent,
  NotificationAlert,
  // NotificationContainer,
  AccountContainer,
  ThemeTogglerWrapper,
  CustomTypography,
  CustomSocialOuterLink,
} from './styles';

const DashboardHeader = ({
  testId = 'dashboardHeader',
  isUnderConstruction,
}: {
  testId?: string;
  isUnderConstruction?: boolean;
}) => {
  const { selectTheme, selectedTheme } = useThemeMode();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { colors } = useTheme();
  const navItems = useNavigationItems({
    color: colors.text.interactive,
    isCombined: true,
  });
  const liveData = useTicketLiveData();
  const pageName = usePageNameDetector();

  const [notificationsShown, setNotificationsShown] = useState<boolean>(false);
  const [accountShown, setAccountShown] = useState<boolean>(false);
  const selectedNotRef = useRef<HTMLDivElement | null>(null);
  const selectedAccRef = useRef<HTMLDivElement | null>(null);

  const closeNotDropdown = useCallback(() => {
    notificationsShown ? setNotificationsShown((prev) => !prev) : null;
  }, [notificationsShown, setNotificationsShown]);

  const closeAccDropdown = useCallback(() => {
    accountShown ? setAccountShown((prev) => !prev) : null;
  }, [accountShown, setAccountShown]);

  useOutsideClick(selectedNotRef, closeNotDropdown);
  useOutsideClick(selectedAccRef, closeAccDropdown);

  // const handleNotificationClick = () => {
  //   setNotificationsShown((prev) => !prev);
  //   accountShown ? setAccountShown((prev) => !prev) : null;
  // };

  const handleAccClick = () => {
    setAccountShown((prev) => !prev);
    notificationsShown ? setNotificationsShown((prev) => !prev) : null;
  };

  const handleTicketClick = () => {
    navigate('/ticket');
    accountShown ? setAccountShown((prev) => !prev) : null;
    notificationsShown ? setNotificationsShown((prev) => !prev) : null;
  };

  const handleNavigate = () => {
    navigate('/');
  };
  const { pathname } = useLocation();
  const navigationShouldDisappear = pathname === '/asset';

  const [searchParams] = useSearchParams();
  const assetCode = searchParams.get('assetCode');
  const { assets } = useAssets();

  const asset = assets.cryptoAssets.find((asset) => asset.code === assetCode);

  const { currentJurisdiction } = useJurisdictionDetector();
  const isGlobalJurisdiction = currentJurisdiction === JurisdictionsEnum.UA;
  const crexonelogo = useRenderObjectByTheme(CrexoneLogoBlack, CrexoneLogo);
  const { isAppDisclaimerVisible } = useAppDisclaimer();

  return (
    <Container
      data-cy={testId}
      isUnderConstruction={isUnderConstruction}
      isAppDisclaimerVisible={isAppDisclaimerVisible}
    >
      <JustifiedContent>
        <HomePage data-cy="headerLogo" onClick={handleNavigate}>
          <BitnetLogo src={isGlobalJurisdiction ? crexonelogo : bitnetLogo} />
        </HomePage>
        <PageNameWrapper>
          <CustomTypography
            testId="headerPageName"
            type="titleOne"
            fontFamily="primaryBold"
          >
            {navigationShouldDisappear ? asset?.name : t(pageName)}
          </CustomTypography>
        </PageNameWrapper>
        <Wrapper>
          <JurisdictionRenderer
            acceptedJurisdictions={[JurisdictionsEnum.GE, JurisdictionsEnum.EU]}
          >
            <TransactionsWrapper
              navigationShouldDisappear={navigationShouldDisappear}
            >
              {navItems.map((item, i) => {
                const handleTransClick = () => {
                  navigate(item.route, {
                    state:
                      item.route === '/deposit' || item.route === '/withdraw'
                        ? { shouldResetStep: true }
                        : undefined,
                  });
                };

                return (
                  <CustomButton
                    key={i}
                    size="sm"
                    icon={item.icon}
                    testId={item.title}
                    buttonType="primary"
                    title={t(item.title)}
                    className={item.className}
                    onClick={handleTransClick}
                  />
                );
              })}
            </TransactionsWrapper>
          </JurisdictionRenderer>
          <JurisdictionRenderer acceptedJurisdictions={[JurisdictionsEnum.EU]}>
            <TransparentButton onClick={handleTicketClick}>
              <IconWrapper
                testId="headerChat"
                color={colors.surface.interactiveDefault}
              >
                {liveData?.comments &&
                Object.keys(JSON.parse(liveData?.comments as unknown as never))
                  .length ? (
                  <NotificationAlert>
                    <Typography
                      type="caption"
                      fontFamily="primaryBold"
                      color={colors.text.interactive}
                    >
                      {
                        Object.keys(
                          JSON.parse(liveData?.comments as unknown as never)
                        ).length
                      }
                    </Typography>
                  </NotificationAlert>
                ) : null}
                <ChatIcon />
              </IconWrapper>
            </TransparentButton>
          </JurisdictionRenderer>
          <JurisdictionRenderer acceptedJurisdictions={[JurisdictionsEnum.GE]}>
            {/* <HeaderChatIcon /> */}
            <HeaderChat chatButtonId="btn_chat" chatIconId="btn_chat2" />
          </JurisdictionRenderer>

          {/* {process.env.ENVIRONMENT === Environment.dev && (
            <NotificationContainer ref={selectedNotRef}>
              <TransparentButton onClick={handleNotificationClick}>
                <IconWrapper
                  testId="headerNotification"
                  color={colors.surface.interactiveDefault}
                >
                  <NotificationAlert>
                    <Typography
                      type="caption"
                      fontFamily="primaryBold"
                      color={colors.text.interactive}
                    >
                      2
                    </Typography>
                  </NotificationAlert>
                  <NotificationIcon color={colors.text.default} />
                </IconWrapper>
              </TransparentButton>
              <NotificationsDropdown
                isShown={notificationsShown}
                setIsShown={setNotificationsShown}
              />
            </NotificationContainer>
          )} */}
          <JurisdictionRenderer acceptedJurisdictions={[JurisdictionsEnum.UA]}>
            <CustomSocialOuterLink
              url={SOCIAL_NETWORK_URLS.crexoneTelegram}
              Icon={<TelegramIcon color={colors.text.interactive} />}
              title={t('common_contact')}
            />
          </JurisdictionRenderer>
          <AccountContainer ref={selectedAccRef}>
            <AccButton onClick={handleAccClick} data-testid="account-dropdown">
              <IconWrapper
                testId="headerAccount"
                color={colors.surface.interactiveDefault}
              >
                <AccountIcon
                  color={colors.text.default}
                  width={24}
                  height={24}
                />
              </IconWrapper>
            </AccButton>
            <AccountDropdown
              isShown={accountShown}
              setIsShown={setAccountShown}
            />
          </AccountContainer>

          <JurisdictionRenderer acceptedJurisdictions={[JurisdictionsEnum.UA]}>
            <ThemeTogglerWrapper>
              <ThemeToggler
                onThemeToggle={selectTheme}
                activeTheme={selectedTheme}
              />
            </ThemeTogglerWrapper>
          </JurisdictionRenderer>
        </Wrapper>
      </JustifiedContent>
    </Container>
  );
};

export default DashboardHeader;
