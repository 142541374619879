import gql from 'graphql-tag';
import { TypedDocumentNode } from '@apollo/client';
import { DefaultArg, DefaultApiResponse } from '@libs/graphql';
import { Asset as AssetNative } from '@libs/types';

import { assetFragment } from '@libs/graphql';

export type Asset = AssetNative;

export type getAllAssetsResponse = {
  getAllAssets: DefaultApiResponse<Asset[]>;
};
export const getAllAssetsQuery: TypedDocumentNode<
  getAllAssetsResponse,
  DefaultArg
> = gql`
  query GetAllAssets {
    getAllAssets {
      ...assetFields
      assetSupportedNetworks {
        id
      }
    }
  }

  ${assetFragment}
`;
