import { useMutation } from '@apollo/client';
import { Button, Typography } from '@libs/components';
import { useCallback } from 'react';
import { deleteCardMutation, getCardsQuery, GetCardsType } from 'src/gql';
import { useCardDetector, useTranslation } from 'src/hooks';
import {
  CancelButton,
  CardDeleteConfirmationContainer,
  CardMaskWrapper,
  CardIconWrapper,
  Icon,
  DeleteIconWrapper,
  CustomDeleteIcon,
} from './styles';

type CardDeleteConfrimationProps = {
  selectedDeleteCard?: GetCardsType;
  closeModal: () => void;
};
export const CardDeleteConfirmation: React.FC<CardDeleteConfrimationProps> = ({
  selectedDeleteCard,
  closeModal,
}) => {
  const { t } = useTranslation();
  const { cardDetector } = useCardDetector();

  const { cardProvider, cardIcon } = cardDetector(
    selectedDeleteCard?.cardMask as string
  );

  const [deleteCard] = useMutation(deleteCardMutation, {
    refetchQueries: [{ query: getCardsQuery }],
  });

  const handleDeleteConfirmation = useCallback(() => {
    if (selectedDeleteCard) {
      deleteCard({
        variables: {
          record: {
            id: Number(selectedDeleteCard.id),
          },
        },
      });
      closeModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteCard, selectedDeleteCard]);

  return (
    <CardDeleteConfirmationContainer>
      <Typography type="subHeadline" fontFamily="primaryBold">
        {t('card_delete_warning')}
      </Typography>
      <CardMaskWrapper>
        <Typography type="subHeadline" fontFamily="primaryMedium">
          {cardProvider + ' ' + selectedDeleteCard?.cardMask}
        </Typography>
      </CardMaskWrapper>

      <CardIconWrapper>
        <Icon src={cardIcon} />
        <DeleteIconWrapper>
          <CustomDeleteIcon />
        </DeleteIconWrapper>
      </CardIconWrapper>
      <Button
        title={t('common_delete')}
        buttonType="secondary"
        destructive
        size="lg"
        onClick={handleDeleteConfirmation}
      />
      <CancelButton
        title={t('common_cancel')}
        buttonType="secondary"
        size="lg"
        onClick={closeModal}
      />
    </CardDeleteConfirmationContainer>
  );
};
