import styled, { css } from 'styled-components';

import { Disclaimer, Radio } from '@libs/components';

import InputRadius from '../input-radius/InputRadius';

type DepositFiatContainerProps = {
  hasPadding: boolean;
};
export const DepositFiatContainer = styled.div<DepositFiatContainerProps>`
  height: 100%;

  ${({ theme, hasPadding }) =>
    hasPadding &&
    css`
      padding: 2.8rem 2.2rem 3.5rem;

      ${theme.media.tablet`
        padding: 2.8rem 3.7rem 3.5rem;
      `}
    `}
`;
export const CustomRadio = styled(Radio)`
  border: none;
  border-radius: 0;
  overflow: visible;
  height: 100%;
  .radio-title {
    text-align: left;
  }

  .radio-icon {
    width: unset;
  }

  .radio-content {
    gap: 1.2rem;
  }
`;

export const BankRadio = styled(Radio)`
  background-color: ${({ theme }) => theme.colors.surface.default};
  .middle_circle {
    background-color: ${({ theme }) => theme.colors.surface.default};
  }
  ${({ checked, theme }) =>
    checked &&
    css`
      border: 0.1rem solid ${theme.colors.interactive.brandColor};
    `}
`;

export const BankRadiosWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.surface.interactiveDefault};
  padding: 1.6rem 1.4rem;
`;
export const CustomCurrencyTogglerWrapper = styled.div`
  margin: 1.2rem 0 1.5rem;
`;

export const StyledDisclaimer = styled(Disclaimer)`
  border: none;

  margin-bottom: 2rem;

  background-color: ${({ theme }) => theme.colors.surface.interactiveDefault};
`;

export const CustomInputRadiusContainer = styled.div`
  margin: 1.2rem 0 3.2rem;
`;

export const InputWrapper = styled.div`
  margin: 2rem 0;
`;

export const FlexWrapper = styled.div`
  display: flex;
  gap: 0.4rem;
  align-items: center;

  img,
  svg {
    height: 3.2rem;
    width: 3.2rem;
  }
`;

export const CustomInputRadius = styled(InputRadius)`
  height: unset;
`;
