import { CustomSvg } from '@libs/components';

const MasterCardIcon = () => {
  return (
    <CustomSvg
      width="32"
      height="20"
      viewBox="0 0 32 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.2441 2.22266H20.7646V17.7724H11.2441V2.22266Z"
        fill="#FF5F00"
      />
      <path
        d="M12.2218 10.0024C12.2207 8.5051 12.5601 7.02715 13.2144 5.6804C13.8688 4.33364 14.8209 3.15338 15.9987 2.22892C14.5399 1.08246 12.788 0.36954 10.9432 0.171647C9.0984 -0.0262461 7.23514 0.298869 5.56637 1.10983C3.8976 1.9208 2.49065 3.1849 1.50632 4.75765C0.521994 6.33041 0 8.14837 0 10.0038C0 11.8591 0.521994 13.6771 1.50632 15.2499C2.49065 16.8226 3.8976 18.0867 5.56637 18.8977C7.23514 19.7086 9.0984 20.0338 10.9432 19.8359C12.788 19.638 14.5399 18.925 15.9987 17.7786C14.8205 16.8538 13.8682 15.6731 13.2138 14.3259C12.5595 12.9786 12.2203 11.5001 12.2218 10.0024V10.0024Z"
        fill="#EB001B"
      />
      <path
        d="M31.9992 10.0005C31.9992 11.856 31.4771 13.6741 30.4926 15.2469C29.5081 16.8197 28.1009 18.0838 26.4319 18.8946C24.7629 19.7055 22.8995 20.0304 21.0546 19.8322C19.2096 19.634 17.4577 18.9207 15.999 17.7739C17.1764 16.8486 18.1282 15.668 18.7827 14.3212C19.4372 12.9744 19.7773 11.4965 19.7773 9.99906C19.7773 8.50162 19.4372 7.02374 18.7827 5.67692C18.1282 4.33009 17.1764 3.14951 15.999 2.22423C17.4577 1.0774 19.2096 0.364125 21.0546 0.165943C22.8995 -0.0322377 24.7629 0.292669 26.4319 1.10352C28.1009 1.91438 29.5081 3.17846 30.4926 4.75128C31.4771 6.32409 31.9992 8.14218 31.9992 9.99771V10.0005Z"
        fill="#F79E1B"
      />
    </CustomSvg>
  );
};

export default MasterCardIcon;
