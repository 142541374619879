import styled from 'styled-components';

export const OptionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.6rem;
`;

export const NewCardWarningContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  padding: 3.2rem;

  ${({ theme }) => theme.media.laptop`
  max-width: 50rem;

`};
`;

export const CardsLabelWrapper = styled.div`
  margin: 2rem 0 1.6rem;
`;
